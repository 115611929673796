import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";

@autoinject
export class formSelectResponseDialog {
    items: { file: string, name: string }[];

    constructor(protected controller: DialogController) {
    }

    activate(params) {
        this.items = params.items;
    }
}
