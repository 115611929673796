import {inject, observable} from 'aurelia-framework';
import {DialogController} from "aurelia-dialog";
import {translations} from "../classes/translations";
import {LOINC} from '../classes/Codes';

const moment = require("moment");
import {PatientItem} from "../classes/Patient/PatientItem";
import * as Fhir from "../classes/FhirModules/Fhir";
import {NitTools} from "../classes/NursitTools";
import {UserService} from "../services/UserService";
import {fhirEnums} from "../classes/fhir-enums";
import {ConfigService} from "../services/ConfigService";

const OBSERVATION_STRUCTURE = {
    "resourceType": fhirEnums.ResourceType.observation,
    "meta": {
        "profile": [
            "http://hl7.org/fhir/StructureDefinition/vitalsigns"
        ]
    },
    "status": "final",
    "category": [],
    "subject": {},
    "effectiveDateTime": '',
    "valueQuantity": {}
};

@inject(Element, DialogController, UserService)
export class ModalVitalchartAdd {
    element: HTMLElement;

    patient: PatientItem;

    dialogController: DialogController;

    userService: UserService;

    dpOptions = {
        useCurrent: true,
        format: 'DD.MM.YYYY HH:mm',
        locale: translations.language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        keepInvalid: true,
        focusOnShow: false
    };

    @observable dateElement;

    dateObject: Date;

    bpSystolic: HTMLInputElement;

    bpDiastolic: HTMLInputElement;

    tempBody: HTMLInputElement;

    tempCareunit: HTMLSelectElement;

    tempStation: HTMLSelectElement;

    heartRate: HTMLInputElement;

    oxygenSaturation: HTMLInputElement;

    respiratoryRate: HTMLInputElement;

    constructor(element, dialogController, userService) {
        this.element = element;
        this.dialogController = dialogController;
        this.userService = userService;

        dialogController.settings.centerHorizontalOnly = true;
    }

    attached() {
        this.element.style.minWidth = "704px";
        this.element.style.width = "60%";

        this.dateObject = new Date();
    }

    activate(params) {
        this.patient = params.patient;
    }

    dateElementChanged() {
        let max = moment().endOf('day');

        if (this.patient.encounter.period && this.patient.encounter.period.end) {
            max = moment(this.patient.encounter.period.end).endOf('day');
        }

        if (moment(this.dateObject).isAfter(max)) {
            this.dateObject = max.toDate();
        }

        let min = moment(max).subtract(7, 'days');

        if (this.patient.encounter.period && this.patient.encounter.period.start) {
            min = moment(this.patient.encounter.period.start).startOf('day');
        }

        this.dateElement.methods.maxDate(max.toDate());
        this.dateElement.methods.minDate(min.toDate());
    }

    submit() {
        const observations = [];

        if (this.bpSystolic.value && this.bpDiastolic.value) {
            observations.push(this.addSystolicBloodPressure());
            observations.push(this.addDiastolicPressure());
        }

        if (this.tempBody.value) {
            observations.push(this.addTemperature());
        }

        if (this.heartRate.value) {
            observations.push(this.addHeartrate());
        }

        if (this.oxygenSaturation.value) {
            observations.push(this.addOxygenSaturation());
        }

        if (this.respiratoryRate.value) {
            observations.push(this.addRespiratoryRate());
        }

        Promise.all(observations).then((result) => {
            if (ConfigService.Debug) console.debug('Observations added', result);

            this.dialogController.ok();
        });
    }

    addSystolicBloodPressure() {
        return this.postObservation(this.createObservationsObject({
            category: [
                {
                    coding: [
                        {
                            system: "http://hl7.org/fhir/observation-category",
                            code: "vital-signs",
                            display: "vital-signs"
                        }
                    ]
                }
            ],
            code: {
                coding: [
                    {
                        system: LOINC.SYSTEM,
                        code: LOINC.CODES.BLOOD_PRESSURE_SYSTOLIC.code,
                        display: LOINC.CODES.BLOOD_PRESSURE_SYSTOLIC.display
                    }
                ],
                text: LOINC.CODES.BLOOD_PRESSURE_SYSTOLIC.display
            },
            subject: {
                reference: "Patient/" + this.patient.id,
            },
            effectiveDateTime: moment(this.dateObject).toJSON(),
            valueQuantity: {
                value: this.bpSystolic.value,
                unit: "mmHg",
                system: "http://unitsofmeasure.org",
                code: "mm[Hg]"
            }
        }));
    }

    addDiastolicPressure() {
        return this.postObservation(this.createObservationsObject({
            category: [
                {
                    coding: [
                        {
                            system: "http://hl7.org/fhir/observation-category",
                            code: "vital-signs",
                            display: "vital-signs"
                        }
                    ]
                }
            ],
            code: {
                coding: [
                    {
                        system: LOINC.SYSTEM,
                        code: LOINC.CODES.BLOOD_PRESSURE_DIASTOLIC.code,
                        display: LOINC.CODES.BLOOD_PRESSURE_DIASTOLIC.display
                    }
                ],
                text: LOINC.CODES.BLOOD_PRESSURE_DIASTOLIC.display
            },
            subject: {
                reference: "Patient/" + this.patient.id,
            },
            effectiveDateTime: moment(this.dateObject).toJSON(),
            valueQuantity: {
                value: this.bpDiastolic.value,
                unit: "mmHg",
                system: "http://unitsofmeasure.org",
                code: "mm[Hg]"
            }
        }));
    }

    addTemperature() {
        const observation: any = {
            category: [
                {
                    coding: [
                        {
                            system: "http://hl7.org/fhir/observation-category",
                            code: "vital-signs",
                            display: "vital-signs"
                        }
                    ]
                }
            ],
            code: {
                coding: [
                    {
                        "system": LOINC.SYSTEM,
                        "code": LOINC.CODES.BODY_TEMPERATURE.code,
                        "display": LOINC.CODES.BODY_TEMPERATURE.display
                    }
                ],
                text: LOINC.CODES.BODY_TEMPERATURE.display
            },
            subject: {
                reference: "Patient/" + this.patient.id,
            },
            effectiveDateTime: moment(this.dateObject).toJSON(),
            valueQuantity: {
                value: this.tempBody.value,
                unit: "C",
                system: "http://unitsofmeasure.org",
                code: "cel"
            }
        };

        return this.postObservation(this.createObservationsObject(observation));
    }

    addHeartrate() {
        return this.postObservation(this.createObservationsObject({
            category: [
                {
                    coding: [
                        {
                            system: "http://hl7.org/fhir/observation-category",
                            code: "vital-signs",
                            display: "vital-signs"
                        }
                    ]
                }
            ],
            code: {
                coding: [
                    {
                        "system": LOINC.SYSTEM,
                        "code": LOINC.CODES.HEART_RATE.code,
                        "display": LOINC.CODES.HEART_RATE.display
                    }
                ],
                text: LOINC.CODES.HEART_RATE.display
            },
            subject: {
                reference: "Patient/" + this.patient.id,
            },
            effectiveDateTime: moment(this.dateObject).toJSON(),
            valueQuantity: {
                value: this.heartRate.value,
                unit: "/min",
                system: "http://unitsofmeasure.org",
                code: "/min"
            }
        }));
    }

    addOxygenSaturation() {
        return this.postObservation(this.createObservationsObject({
            category: [
                {
                    coding: [
                        {
                            system: "http://hl7.org/fhir/observation-category",
                            code: "vital-signs",
                            display: "vital-signs"
                        }
                    ]
                }
            ],
            code: {
                coding: [
                    {
                        "system": LOINC.SYSTEM,
                        "code": LOINC.CODES.OXYGEN_SATURATION.code,
                        "display": LOINC.CODES.OXYGEN_SATURATION.display
                    }
                ],
                text: LOINC.CODES.OXYGEN_SATURATION.display
            },
            subject: {
                reference: "Patient/" + this.patient.id,
            },
            effectiveDateTime: moment(this.dateObject).toJSON(),
            valueQuantity: {
                value: this.oxygenSaturation.value,
                unit: "%",
                system: "http://unitsofmeasure.org",
                code: "%"
            }
        }));
    }

    addRespiratoryRate() {
        return this.postObservation(this.createObservationsObject({
            category: [
                {
                    coding: [
                        {
                            system: "http://hl7.org/fhir/observation-category",
                            code: "vital-signs",
                            display: "vital-signs"
                        }
                    ]
                }
            ],
            code: {
                coding: [
                    {
                        "system": LOINC.SYSTEM,
                        "code": LOINC.CODES.RESPIRATORY_RATE.code,
                        "display": LOINC.CODES.RESPIRATORY_RATE.display
                    }
                ],
                text: LOINC.CODES.RESPIRATORY_RATE.display
            },
            subject: {
                reference: "Patient/" + this.patient.id,
            },
            effectiveDateTime: moment(this.dateObject).toJSON(),
            valueQuantity: {
                value: this.respiratoryRate.value,
                unit: "/min",
                system: "http://unitsofmeasure.org",
                code: "/min"
            }
        }));
    }

    createObservationsObject(data) {
        const observationData = NitTools.Clone(OBSERVATION_STRUCTURE);

        if (this.userService.practitioner) {
            observationData.performer = [{
                reference: `Practitioner/${this.userService.practitioner.id}`,
                display: this.userService.fullNameOrUsername
            }];
        }

        Object.assign(observationData, data);

        return observationData;
    }

    postObservation(data: any) {
        if (ConfigService.Debug) console.debug(data);

        return Fhir.Rest.GetClient().createRequest("Observation").asPost().withContent(data).send();
    }
}

