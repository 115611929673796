import {bindable} from "aurelia-framework";
import {Log} from "../classes/log";

export class formularSelectHeader {
    @bindable items;
    @bindable selectedid;
    @bindable callback;
    @bindable buttontitle;
    @bindable showbutton;
    @bindable showsavebutton;
    @bindable savebuttoncallback;
    @bindable printbuttoncallback;
    @bindable showprintbutton;

    printCallback() {
        if (this.printbuttoncallback) {
            this.printbuttoncallback();
        }
    }

    printText: string = "Print";

    saveButtonClicked(status: string) {
        if (!this.savebuttoncallback) {
            console.warn("SaveButton is visible, but no Callback for Click assigned");
            return;
        }

        this.savebuttoncallback(this, status);
    }

    callCallback() {
        if (typeof this.callback === "function") {
            this.callback();
        }
    }

    attached() {
        let router = document.getElementById("patient_router");
        if (router) router.classList.add("margin-top-46");

        let childView = document.getElementById("patient_child_view_parent");
        if (childView) childView.classList.add("padding-bottom-200");
    }

    detached() {
        let router = document.getElementById("patient_router");
        if (router) router.classList.remove("margin-top-46");

        let childView = document.getElementById("patient_child_view_parent");
        if (childView) childView.classList.remove("padding-bottom-200");
    }
}
