import { autoinject, bindable } from "aurelia-framework";
import { PatientItem } from "../classes/Patient/PatientItem";
import { DialogController, DialogService } from "aurelia-dialog";

@autoinject
export class ModalRelationActions {
    model;
    patient: PatientItem;
    selectedPatientId: string;

    constructor(protected controller: DialogController) {
    }

    activate(params) {
        this.model = params;
        this.patient = params.patient;
        if (params.children.length > 0)
            this.selectedPatientId = params.children[0].encounterId;
    }

    async submit() {
        this.controller.ok(this.selectedPatientId);
    }

    async cancel() {
        this.controller.cancel();
    }
}
