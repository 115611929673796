import {inject, observable} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {translations} from "../classes/translations";

const moment = require("moment");
import {LOINC} from "../classes/Codes";
import {NitTools} from "../classes/NursitTools";
import {UserService} from "../services/UserService";
import {FhirService} from "../services/FhirService";
import {fhirEnums} from "../classes/fhir-enums";
import {ConfigService} from "../services/ConfigService";
import {RuntimeInfo} from "../classes/RuntimeInfo";

@inject(Element, DialogController, DialogService, UserService)

export class ModalMedication {
    element: HTMLElement;
    controller: DialogController;
    dialogService: DialogService;
    userService: UserService;

    dpOptions = {
        format: 'HH:mm',
        locale: translations.language,
        showTodayButton: false,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false,
        keepInvalid: false
    };

    request;
    administration;
    patient;
    currentDay;

    @observable picker;

    name: string;
    schedule: string;
    shiftReport: boolean;
    reason: string;
    otherReason: string;
    comment: string = '';
    dateObject;
    dateValue;
    showDate;

    editMode = false;
    _fhirService : FhirService;
    get fhirService() : FhirService {
        if (!this._fhirService)
            this._fhirService = new FhirService();

        return this._fhirService;
    }

    constructor(element, dialogController: DialogController, dialogService: DialogService, userService: UserService) {
        this.element = element;
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.userService = userService;

        this.controller.settings.centerHorizontalOnly = true;
    }

    attached() {
        this.element.style.maxWidth = "860px";
    }

    async activate(data) {
        this.patient = data.patient;
        this.request = data.request;
        this.currentDay = data.currentDay;
        this.name = this.request.name;
        this.schedule = this.request.schedule;
        this.showDate = data.showDate || false;

        if (this.showDate) {
            this.dpOptions.format = 'DD.MM.YYYY HH:mm';
        }

        if (data.administration) {
            const administration = NitTools.Clone(data.administration);

            this.editMode = true;
            this.administration = {
                dose: administration.dose,
                status: administration.status,
                resource: administration.resource
            };

            this.dateObject = moment(this.administration.resource.effectiveDateTime).toDate();
            this.comment = administration.resource.note && administration.resource.note[0].text || '';
            this.shiftReport = Boolean(administration.resource.supportingInformation);

            if (this.administration.resource.notGiven) {
                const reason = this.administration.resource.reasonNotGiven[0];

                this.reason = reason.coding[0].code;
                this.otherReason = this.reason == 'other' ? reason.text : null;
            }
        } else {
            const now = moment();
            const date = moment(this.currentDay).add(now.hours(), 'hours').add(now.minutes(), 'minutes');
            const administration = {
                resourceType: fhirEnums.ResourceType.medicationAdministration,
                status: "in-progress",
                medicationReference: {
                    reference: this.request.resource.medicationReference.reference,
                },
                subject: {
                    reference: `Patient/${this.patient.id}`
                },
                context: {
                    reference: `Encounter/${this.patient.encounterId}`
                },
                prescription: {
                    reference: `MedicationRequest/${this.request.resource.id}`
                },
                reasonCode: [{
                    coding: [{
                        system: RuntimeInfo.SystemHeader + '/medication-status',
                        code: 'open'
                    }]
                }],
                dosage: {
                    dose: {
                        value: this.request.resource.dosageInstruction[0].doseQuantity.value,
                        unit: this.request.resource.dosageInstruction[0].doseQuantity.unit,
                    }
                },
                effectiveDateTime: date.toJSON()
            };

            this.dateObject = date.toDate();
            this.administration = {
                status: this.getStatus(administration),
                dose: administration.dosage.dose,
                resource: administration
            }
        }
    }

    pickerChanged() {
        if (this.showDate) {
            this.picker.methods.minDate(moment(this.showDate).add(22, 'hours').toDate());
            this.picker.methods.maxDate(moment(this.showDate).add(1, 'day').add(6, 'hours').subtract(1, 'minute').toDate());
        }
    }

    getStatus(administration) {
        switch (administration.status) {
            case 'in-progress': {
                return administration.reasonCode[0].coding.find(o => o.system === RuntimeInfo.SystemHeader + "/medication-status").code;
            }
            case 'completed': {
                return 'administered';
            }
            case 'stopped': {
                return 'not-administered';
            }
        }
    }

    changeStatus(status) {
        this.administration.status = status;
    }

    submit() {
        const dateObject = moment(this.dateObject);
        const date = moment(this.currentDay).add(dateObject.hours(), 'hours').add(dateObject.minutes(), 'minutes');

        if (this.showDate) {
            this.administration.resource.effectiveDateTime = dateObject.toJSON();
        } else {
            this.administration.resource.effectiveDateTime = date.toJSON();
        }

        if (this.administration.status === 'not-administered') {
            this.administration.resource.notGiven = true;
            this.administration.resource.reasonNotGiven = [{
                coding: [{
                    system: RuntimeInfo.SystemHeader + '/medication-not-given-reason',
                    code: this.reason
                }],
                text: this.reason == 'other' ? this.otherReason : null
            }];

            delete this.administration.resource.note;
        } else {
            delete this.administration.resource.notGiven;
            delete this.administration.resource.reasonNotGiven;

            this.administration.resource.note = [{text: this.comment}];
        }

        switch (this.administration.status) {
            case 'open':
            case 'placed':
            case 'controlled':
            case 'distributed': {
                this.administration.resource.status = 'in-progress';
                this.administration.resource.reasonCode = [{
                    coding: [{
                        system: RuntimeInfo.SystemHeader + '/medication-status',
                        code: this.administration.status
                    }]
                }];
                break;
            }
            case 'administered': {
                this.administration.resource.status = 'completed';
                delete this.administration.resource.reasonCode;
                break;
            }
            case 'not-administered': {
                this.administration.resource.status = 'stopped';
                delete this.administration.resource.reasonCode;
                break;
            }
        }

        if (this.shiftReport) {
            if (!this.administration.resource.supportingInformation) {
                const report = {
                    resourceType: fhirEnums.ResourceType.observation,
                    subject: {reference: `${fhirEnums.ResourceType.patient}/${this.patient.id}`},
                    context: {reference: `${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`},
                    status: 'registered',
                    code: {
                        coding: [{
                            system: LOINC.SYSTEM,
                            code: LOINC.CODES.REPORT.code
                        }]
                    },
                    effectiveDateTime: this.dateObject,
                    valueString: `${translations.translate(this.administration.status)} um ${moment(this.dateObject).format('DD.MM.YYYY HH:mm')}. ${this.name} ${this.schedule} ${this.comment}`,
                    comment: this.userService.username,
                    component: [{
                        code: {
                            text: 'shift'
                        },
                        valueString: 'day'
                    }, {
                        code: {
                            text: 'mark-supplement'
                        },
                        valueString: 'false'
                    }, {
                        code: {
                            text: 'nursing-transfer'
                        },
                        valueString: 'false'
                    },
                        {
                            code: {
                                text: 'additional-info'
                            },
                            valueString: 'false'
                        }]
                };

                return this.fhirService.create(report).then((result) => {
                    this.administration.resource.supportingInformation = {reference: `${fhirEnums.ResourceType.observation}/${result.id}`};

                    return this.processAdministration();
                });
            }
        } else {
            if (this.administration.resource.supportingInformation) {
                return this.deleteShiftReport(this.administration.resource.supportingInformation).then(() => {
                    delete this.administration.resource.supportingInformation;

                    return this.processAdministration();
                })
            }
        }

        return this.processAdministration();
    }

    deleteShiftReport(supportingInformation) {
        const [resourceType, id] = supportingInformation[0].reference.split('/');

        return this.fhirService.delete({
            id,
            resourceType
        });
    }

    processAdministration() {
        if (this.editMode) {
            return this.fhirService.update(this.administration.resource).then((administration) => {
                return this.controller.ok({
                    resource: administration,
                    status: this.administration.status,
                    dose: this.administration.dose
                });
            });
        } else {
            return this.fhirService.create(this.administration.resource).then((administration) => {
                return this.controller.ok({
                    resource: administration,
                    status: this.administration.status,
                    dose: this.administration.dose
                });
            });
        }
    }

    close() {
        this.controller.cancel();
    }
}
