import {autoinject} from 'aurelia-framework';
import {DialogController, DialogService} from "aurelia-dialog";
import {UserService} from "../services/UserService";
import {I18N} from "aurelia-i18n";
import {MediaItem} from "../../views/patient/other-images";
import {FhirService} from "../services/FhirService";
import {woundImageDialog} from "../../views/patient/wunden/wound-image-dialog";
import {translations} from "../classes/translations";
import {PatientService} from "../services/PatientService";
import {Router} from "aurelia-router";
import ToolbarOptions = Viewer.ToolbarOptions;
import Viewer from "viewerjs";

const moment = require("moment");

@autoinject
export default class ModalPlanningPrescription {
    dialogController;
    dialogService;
    userService;
    fhirService;
    i18n;
    router;
    patient;
    codeSystem;
    procedureRequest;
    images = [];
    viewer;
    openingImageIndex = -1;

    get hasSelectedImage() {
        return this.images.findIndex((img) => img.selected) > -1
    }

    constructor(dialogController: DialogController, dialogService: DialogService, userService: UserService, fhirService: FhirService, i18n: I18N, router: Router) {
        this.dialogController = dialogController;
        this.dialogService = dialogService;
        this.userService = userService;
        this.fhirService = fhirService;
        this.i18n = i18n;
        this.router = router;
    }

    async activate(model) {
        this.patient = model.patient;
        this.codeSystem = model.codeSystem;
        this.procedureRequest = model.procedureRequest;

        const subtypeName = FhirService.FhirVersion > 3 ? 'modality' : 'subtype';
        const urlDoctor = `Media?_format=json&patient=${this.patient.id}&${subtypeName}=order-image&${subtypeName}=thumbnail`;

        const medias = <any[]>await this.fhirService.fetch(urlDoctor);
        for (const media of medias.filter(o => o.identifier)) {
            const mi = new MediaItem();
            mi.media = media;
            this.images.push({
                selected: false,
                mi
            });
        }

        if (this.procedureRequest.supportingInfo) {
            const [,existingMediaId] = this.procedureRequest.supportingInfo[0].reference.split('/')

            this.images.forEach((img) => {
                if (img.mi.media.id === existingMediaId) {
                    img.selected = true
                }
            })
        }
    }

    submit() {
        const selectedImage = this.images.find((img) => img.selected)

        this.dialogController.ok(selectedImage.mi.media.id)
    }

    cancel() {
        this.dialogController.cancel();
    }

    async openImageUrl(image, idx) {
        if (this.openingImageIndex > -1) {
            return
        }

        let media: any = image.mi.media
        let targetMediaId = media.id;

        this.openingImageIndex = idx

        if (media.identifier) {
            let ident = media.identifier.find(o => o.system.endsWith('media-source-id'));
            if (ident != null)
                targetMediaId = ident.value;

            if (targetMediaId && targetMediaId !== media.id) {
                media = <any>await this.fhirService.get('Media/' + targetMediaId);
            }
        }

        const drawingUrl = 'Media?identifier=http://nursit-institute.com/fhir/StructureDefinition/image-objects-target|' + targetMediaId;
        const drawingMedias = await this.fhirService.fetch(drawingUrl);
        let drawingObjects = {};
        try {
            if (drawingMedias && drawingMedias[0]) {
                const drawingMedia = <any>drawingMedias[0];
                drawingObjects = JSON.parse(atob(drawingMedia.content.data));
            }
        } catch (EX) {
            console.warn(EX);
            drawingObjects = null;
        }

        this.dialogService.open({
            viewModel: woundImageDialog,
            model: {
                imageSource: (<any>media.content).data,
                title: translations.translate('information'),
                media: <any>media,
                thumbnail: null,
                mediaId: media.id,
                drawingObjects: drawingObjects,
                showDraw: false
            },
            lock: true,
        }).then(() => {
            let img: HTMLImageElement = document.querySelector(".touch-image");
            img.style.display = "none";

            let optionShow: any = {show: true, size: 'large'};
            let options: ToolbarOptions = {
                prev: false, play: false, next: false, oneToOne: false,
                flipHorizontal: optionShow, flipVertical: optionShow, reset: optionShow, rotateLeft: optionShow,
                rotateRight: optionShow, zoomIn: optionShow, zoomOut: optionShow
            };

            this.viewer = new Viewer(img, {
                inline: true,
                fullscreen: false,
                navbar: false,
                transition: false,
                title: false,
                minZoomRatio: 0.5,
                maxZoomRatio: 5,
                toolbar: options
            });

            this.viewer.view();
        })
        this.openingImageIndex = -1
    }

    imageSelected(idx) {
        this.images.forEach((img, i) => {
            if (i !== idx) {
                img.selected = false
            }
        })
    }
}
