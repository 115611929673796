import {inject} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {ConfigService} from "../services/ConfigService";
import {FhirService} from "../services/FhirService";
import * as environment from "../../../config/environment.json";
import { UserService } from 'resources/services/UserService';

@inject(UserService, DialogController, DialogService, Element)

export class Modal3dBody {
    userService: UserService
    controller: DialogController;
    dialogService: DialogService;
    patient
    iframeContainer
    iframeUrl
    element
    tokenUpdateListenerRemove

    constructor(userService: UserService, dialogController: DialogController, dialogService: DialogService, element: Element) {
        this.userService = userService;
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.element = element;
    }

    activate(params) {
        this.patient = params.patient
    }

    async attached() {
        const config = ConfigService.GetFormSettings(ConfigService.FormNames.Wounds);
        const loginData = sessionStorage.getItem(environment.sessionName);

        await this.userService.forceRefreshToken();
        this.tokenUpdateListenerRemove = this.userService.addTokenUpdateListener(this.sendNewToken.bind(this));
        let oauth: any;

        if (ConfigService.UseOAuth2) {
            ConfigService.EnsureTokens();

            oauth = JSON.stringify({
                access_token: ConfigService.AccessToken,
            });
        }

        const query = {
            login: loginData,
            oauth,
            server: FhirService.Endpoint,
            origin: window.location.origin,
            encounterId: this.patient.encounterId,
        };

        this.iframeUrl = `${config?.settings?.body3d?.url}?` + Object.keys(query).map((key) => {
            return `${key}=${encodeURIComponent(query[key])}`
        }).join('&')
        this.element.style.width = '100%'
    }

    detached() {
        this.tokenUpdateListenerRemove();
    }

    sendNewToken() {
        if (this.iframeContainer) {
            try {
                this.iframeContainer.contentWindow.postMessage({
                    name: 'update-token',
                    data: sessionStorage.getItem(environment.oauth2SessionName)
                }, this.iframeUrl);
            } catch (e) {
            }
        }
    }

    close() {
        this.controller.cancel();
    }
}
