import {autoinject} from 'aurelia-framework';
import {translations} from "../classes/translations";
import {DialogController, DialogService} from "aurelia-dialog";
import {FhirService} from "../services/FhirService";
import {UserService} from "../services/UserService";
import {RuntimeInfo} from "../classes/RuntimeInfo";

@autoinject
export class ModalTodoAddDiagnostic {
    controller;
    dialogService;
    fhirService;
    userService;

    dpOptions = {
        format: RuntimeInfo.DateTimeFormat,
        locale: translations.language,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false,
        keepInvalid: false
    };

    dateObject: Date;
    dateValue: string;
    dateElement;

    codeSystem;
    catalog = [];
    item = null;
    priority = 'routine';
    comment = '';
    practitioner;

    constructor(dialogController: DialogController, dialogService: DialogService, fhirService: FhirService, userService: UserService) {
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.fhirService = fhirService;
        this.userService = userService;

        this.controller.settings.centerHorizontalOnly = true;
    }

    async activate(data) {
        const that = this;

        this.codeSystem = data.codeSystem;
        this.catalog = [];

        if (this.userService.practitioner) {
            this.practitioner = this.userService.fullNameOrUsername;
        }

        function filterCodes(concepts) {
            for (let i = 0; i < concepts.length; i++) {
                const concept = concepts[i];
                const rootData = concept.code.split('.')[0];

                if (rootData != 100) {
                    continue;
                }

                if (concept.concept) {
                    filterCodes(concept.concept);
                } else {
                    that.catalog.push(concept);
                }
            }
        }

        filterCodes(this.codeSystem.concept);
    }

    submit() {
        if (!this.item || !this.dateValue) return;

        this.controller.ok({
            item: this.item,
            dateObject: this.dateObject,
            priority: this.priority,
            comment: this.comment
        });
    }

    close() {
        this.controller.cancel();
    }
}
