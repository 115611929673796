import {bindable} from "aurelia-framework";

export class LoadingButton {
    @bindable text: string = "Unlabelled";
    @bindable loading: boolean = false;
    @bindable buttonClass: string;

    get btnClass() {
        return this.buttonClass || 'btn-default';
    }
}
