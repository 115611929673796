import {inject, observable, computedFrom} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {ModalBodyMapAdd} from "./modal-body-map-add";

const moment = require("moment");
import {Prompt} from "./prompt";
import {translations} from "../classes/translations";
import * as Fhir from "../classes/FhirModules/Fhir";
import {PatientItem} from "../classes/Patient/PatientItem";

const svgPanZoom = require('svg-pan-zoom');
const Hammer = require('hammerjs');
import * as environment from "../../../config/environment.json";
import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {LOINC} from "../classes/Codes";
import {FhirService} from "../services/FhirService";
import {UserService} from "../services/UserService";
import {fhirEnums} from "../classes/fhir-enums";
import {BodymapService} from "../services/BodymapService";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../services/ConfigService";
import {RuntimeInfo} from "../classes/RuntimeInfo";

@inject(Element, DialogController, DialogService, FhirService, UserService, BodymapService, I18N)
export class ModalBodyMap {
    fhirService: FhirService;

    element: HTMLElement;

    controller: DialogController;

    dialogService: DialogService;

    userService: UserService;

    bodymapService: BodymapService;

    svg: SVGSVGElement;

    i18n: I18N;

    _onMouseMove: () => void;

    _onMouseDown: () => void;

    _onMouseUp: () => void;

    _onResize: () => void;

    _onSvgMouseMove: () => void;

    _onTouchStart: () => void;

    _onTouchMove: () => void;

    _onSvgTouchMove: () => void;

    _onTouchEnd: () => void;

    _onTouchCancel: () => void;

    _onTimelineMouseDown: () => void;

    _onTimelineTouchStart: () => void;

    _onTimelineSliderMouseDown: () => void;

    _onTimelineSliderMouseUp: () => void;

    _onTimelineSliderTouchStart: () => void;

    _onTimelineSliderTouchEnd: () => void;

    _onTimelineSliderMouseMove: () => void;

    _onTimelineSliderTouchMove: () => void;

    isSubmitted = false;

    _patient: PatientItem;

    shownPointsAll = true

    shownPoints = [true, true, true, true, true, true, true, true, true]

    shownPointsAllElement

    set patient(value: PatientItem) {
        this._patient = value;
    }

    get patient(): PatientItem {
        return this._patient || PatientItem.SelectedPatient || PatientItem.LastLoadedPatient;
    }

    detailShown = false;

    fontSize = 16;

    points: any = {};

    data: any = {};

    svgPoints: any = {};

    dataMarkedDelete = [];

    tooltipRect: SVGRectElement;

    tooltipText: SVGTextElement;

    dragPoint = {
        isDragged: false,
        type: null,
        idx: -1,
        number: 0,
        coordinates: {
            x: 0,
            y: 0
        }
    };

    panAndZoomInstance;

    isMouseDown = false;

    prevMouseMovePos = {x: 0, y: 0};

    mouseDownPos = {x: 0, y: 0};

    panAndZoom = {x: 0, y: 0, realZoom: 1, zoom: 1};

    isMultiselect = false;

    showMultiselectDropdown = false;

    selectedMultiselect = null;

    multiselected = [];

    groups = [];

    enabledTypes = [];

    disabledTypes = [];

    timeline: HTMLElement;

    timelineSlider: HTMLElement;

    isTimelineSliderDragged = false;

    timelineSliderStartPos = {x: 0, y: 0};

    timelineDateStart;

    timelineDateEnd;

    timelineDates = [];

    timelinePoints = [];

    timelineSliderDateTime;

    timelinePredefinedEndDateTime;

    burnsConfig;

    observations = [];

    burnAreaObservation;

    burnDisplayLevel = 0;

    burnDisplayLevelText = '';

    burnDisplayFirstDegree = '0';

    burnDisplaySecondDegree = '0';

    burnDisplayThirdDegree = '0';

    exportMap = false;

    isInfoVisible = false;

    config;

    timeframes = {};

    constructor(element, dialogController: DialogController, dialogService: DialogService, fhirService: FhirService, userService: UserService, bodyMapService: BodymapService, i18n: I18N) {
        this.element = element;
        this.dialogService = dialogService;
        this.controller = dialogController;
        this.fhirService = fhirService;
        this.userService = userService;
        this.bodymapService = bodyMapService;
        this.i18n = i18n;

        this.controller.settings.centerHorizontalOnly = true;
        // noinspection JSUnusedLocalSymbols
        this.controller.settings.position = (dialogContainer: Element, dialogOverlay: Element) => {
            (<HTMLElement>dialogContainer.children[0]).style.padding = '8px';
        };
    }

    activate(params) {
        if (params.patient) {
            this.patient = params.patient;

            this.timelinePredefinedEndDateTime = params.timelineDate;

            this.enabledTypes = params.enabledTypes || [];

            this.disabledTypes = params.disabledTypes || [];

            this.exportMap = params.exportMap;
        }
    }

    async attached() {
        this._onMouseMove = this.onMouseMove.bind(this);
        this._onMouseDown = this.onMouseDown.bind(this);
        this._onMouseUp = this.onMouseUp.bind(this);
        this._onResize = this.onResize.bind(this);
        this._onSvgMouseMove = this.onSvgMouseMove.bind(this);
        this._onTouchStart = this.onTouchStart.bind(this);
        this._onTouchMove = this.onTouchMove.bind(this);
        this._onSvgTouchMove = this.onSvgTouchMove.bind(this);
        this._onTouchEnd = this.onTouchEnd.bind(this);
        this._onTouchCancel = this.onTouchCancel.bind(this);
        this._onTimelineMouseDown = this.onTimelineMouseDown.bind(this);
        this._onTimelineTouchStart = this.onTimelineTouchStart.bind(this);
        this._onTimelineSliderMouseDown = this.onTimelineSliderMouseDown.bind(this);
        this._onTimelineSliderMouseUp = this.onTimelineSliderMouseUp.bind(this);
        this._onTimelineSliderTouchStart = this.onTimelineSliderTouchStart.bind(this);
        this._onTimelineSliderTouchEnd = this.onTimelineSliderTouchEnd.bind(this);
        this._onTimelineSliderMouseMove = this.onTimelineSliderMouseMove.bind(this);
        this._onTimelineSliderTouchMove = this.onTimelineSliderTouchMove.bind(this);

        if (RuntimeInfo.IsMobile) {
            this.fontSize *= 1.7;

            Object.keys(this.points).forEach((point) => {
                this.points[point].textOffset += 2;
                this.points[point].size *= 2;
            });
        }

        this.onResize();

        if (this.exportMap) {
            (<HTMLElement>document.querySelector('ux-dialog-overlay')).style.opacity = '0';
        }

        await this.createData();

        if (this.patient) {
            this.loadData();
        } else {
            this.bindEvents();
        }
    }

    async createData() {
        const wounds = await this.bodymapService.getWounds();

        wounds.forEach((wound) => {
            this.points[wound.name] = {
                num: 0,
                img: wound.icon,
                size: wound.size,
                textOffset: wound.textOffset,
                noSubtype: !wound.hasOwnProperty('subtypes')
            };
            this.data[wound.name] = [];
            this.svgPoints[wound.name] = [];
        });
    }

    bindEvents() {
        this.createTooltip();

        if (RuntimeInfo.IsMobile) {
            this.svg.addEventListener('touchstart', this._onTouchStart, false);
            this.svg.addEventListener('touchend', this._onTouchEnd, false);
            this.svg.addEventListener('touchcancel', this._onTouchCancel, false);
            this.svg.addEventListener('touchmove', this._onTouchMove, false);
            this.svg.addEventListener('touchmove', this._onSvgTouchMove, false);
        } else {
            this.svg.addEventListener('mousemove', this._onMouseMove, false);
            this.svg.addEventListener('mousedown', this._onMouseDown, false);
            this.svg.addEventListener('mouseup', this._onMouseUp, false);
            this.svg.addEventListener('mousemove', this._onSvgMouseMove, false);
        }

        window.addEventListener('resize', this._onResize);

        this.timeline.addEventListener('mousedown', this._onTimelineMouseDown, false);
        this.timeline.addEventListener('touchstart', this._onTimelineTouchStart, false);

        this.timelineSlider.addEventListener('mousedown', this._onTimelineSliderMouseDown, false);
        this.timelineSlider.addEventListener('mouseup', this._onTimelineSliderMouseUp, false);
        this.timelineSlider.addEventListener('touchstart', this._onTimelineSliderTouchStart, false);


        document.addEventListener('mouseup', this._onTimelineSliderMouseUp, false);
        document.addEventListener('mousemove', this._onTimelineSliderMouseMove, false);
        document.addEventListener('touchmove', this._onTimelineSliderTouchMove, false);
        document.addEventListener('touchend', this._onTimelineSliderTouchEnd, false);
        document.addEventListener('touchcancel', this._onTimelineSliderTouchEnd, false);
    }

    async loadData() {
        const groups = await this.loadGroups();
        const elementsFromGroups = {};

        groups.forEach((group, idx) => {
            group.resource.entry.forEach((entry) => {
                const entryId = entry.item.reference.split('/')[1];

                elementsFromGroups[entryId] = idx;
            });
        });

        this.burnsConfig = await this.loadBurns();

        this.startPanAndZoom();

        return this.fhirService.fetch(`Observation?encounter=${this.patient.encounterId}`).then((result: any[]) => {
            this.observations = result;

            this.observations.filter((item) => item.code && item.code.text === 'body-map').forEach((item) => {
                const bodySite = item.bodySite.text.split('_');
                const data: any = {
                    id: item.id,
                    index: null,
                    identifier: Array.isArray(item.identifier) && item.identifier.length > 0 ? item.identifier[0].value : 1,
                    status: item.status,
                    currentDate: new Date(item.issued),
                    type1: item.category[0].text,
                    info: (FhirService.FhirVersion > 3 ? item.note && item.note[0].text : item.comment) || '',
                    date: item.effectiveDateTime || (item.effectivePeriod && item.effectivePeriod.start ? item.effectivePeriod.start : (this.patient.encounter.period ? moment(this.patient.encounter.period.start).toDate() : new Date())),
                    group: null,
                    timeType: 'time-point',
                    availableAtAdmission: false,
                    point: {
                        x: bodySite[0],
                        y: bodySite[1]
                    }
                };

                if (item.extension) {
                    const availableAtAdmission = item.extension.find((ext) => ext.url === environment.nursItStructureDefinition + 'available-at-admission');

                    if (availableAtAdmission) {
                        data.availableAtAdmission = availableAtAdmission.valueBoolean;
                    }
                }

                if (item.effectivePeriod && item.effectivePeriod.extension) {
                    const timeFrame = item.effectivePeriod.extension.find((ext) => ext.url === environment.nursItStructureDefinition + 'timeframe-days');

                    if (timeFrame) {
                        data.timeType = 'time-frame';
                        data.timeframe = timeFrame.valueInteger;
                    }
                }

                if (item.effectivePeriod && item.effectivePeriod.end) {
                    data.healDate = item.effectivePeriod.end;
                }

                if (item.bodySite.coding) {
                    data.location = item.bodySite.coding[0].display;

                    if (data.location) {
                        data.locationLocalized = translations.translate(data.location);
                    }
                }

                if (item.component) {
                    data.type2 = item.component[0].code.text;

                    if (item.component[1]) {
                        data.type3 = item.component[1].code.text;
                    }
                }

                this.data[data.type1].push(data);

                data.index = this.data[data.type1].length - 1;
            });

            Object.keys(this.data).forEach((key) => {
                this.data[key].sort((a, b) => {
                    return a.identifier - b.identifier;
                }).forEach((item, idx) => {
                    const pointData = this.points[key];

                    pointData.num = Math.max(pointData.num, item.identifier);

                    if (item.status == 'registered') {
                        const groupIdx = elementsFromGroups.hasOwnProperty(item.id) ? elementsFromGroups[item.id] : null;

                        this.addPoint(idx, key, item.identifier, {
                            x: parseFloat(item.point.x),
                            y: parseFloat(item.point.y)
                        }, item.location, groupIdx !== null ? this.groups[groupIdx] : null);
                    }
                });
            });

            this.realignPoints();
            this.createTimeline(true);
            this.createBurnAreaObservation();
            this.calculateBurns();

            if (this.exportMap) {
                this.panAndZoomInstance.destroy();
                this.controller.ok({svg: this.svg, data: this.data});
            } else {
                this.bindEvents();
            }
        });
    }

    createBurnAreaObservation() {
        const bodyBurnResult = this.observations.filter((item) => item.code && item.code.text === 'body-burn');

        if (bodyBurnResult.length > 0) {
            this.burnAreaObservation = bodyBurnResult[0];
        } else {
            this.burnAreaObservation = {
                resourceType: fhirEnums.ResourceType.observation,
                status: 'registered',
                issued: moment().toJSON(),
                code: {
                    text: 'body-burn'
                },
                subject: {
                    reference: "Patient/" + this.patient.id,
                },
                [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: {
                    reference: "Encounter/" + this.patient.encounterId
                },
                effectiveDateTime: moment().toJSON(),
                valueCodeableConcept: {
                    coding: [{
                        system: LOINC.SYSTEM,
                        code: '',
                        display: ''
                    }],
                    text: translations.translate('burns_none')
                },
                component: [{
                    code: {
                        coding: [{
                            system: LOINC.SYSTEM,
                            code: LOINC.CODES.BURN_FIRST_DEGREE.code,
                            display: LOINC.CODES.BURN_FIRST_DEGREE.display
                        }],
                        text: '1'
                    },
                    valueQuantity: {
                        value: 0
                    }
                }, {
                    code: {
                        coding: [{
                            system: LOINC.SYSTEM,
                            code: LOINC.CODES.BURN_SECOND_DEGREE.code,
                            display: LOINC.CODES.BURN_SECOND_DEGREE.display
                        }],
                        text: '2'
                    },
                    valueQuantity: {
                        value: 0
                    }
                }, {
                    code: {
                        coding: [{
                            system: LOINC.SYSTEM,
                            code: LOINC.CODES.BURN_THIRD_DEGREE.code,
                            display: LOINC.CODES.BURN_THIRD_DEGREE.display
                        }],
                        text: '3'
                    },
                    valueQuantity: {
                        value: 0
                    }
                }]
            };
        }

        if (this.userService.practitioner) {
            this.burnAreaObservation.performer = [{
                reference: `Practitioner/${this.userService.practitioner.id}`,
                display: this.userService.fullNameOrUsername
            }];
        }
    }

    loadGroups() {
        return this.fhirService.fetch(`List?encounter=${this.patient.encounterId}&code=${RuntimeInfo.SystemHeader}/body-map-group|`).then((result: any[]) => {
            result.forEach((group) => {
                const type = group.code.coding.find(o => o.system === RuntimeInfo.SystemHeader + "/body-map-group");

                this.createGroup(type, group, false);
            });

            return this.groups;
        });
    }

    startPanAndZoom() {
        const self = this;
        let lastTouchMove;

        function beforePan(oldPan, newPan) {
            if (self.dragPoint.isDragged || lastTouchMove && lastTouchMove.touches && lastTouchMove.touches.length > 1) {
                return false;
            }

            self.panAndZoom.x = newPan.x;
            self.panAndZoom.y = newPan.y;

            self.realignPoints();

            return newPan;
        }

        function beforeZoom(oldZoom, newZoom) {
            const pan = self.panAndZoomInstance.getPan();

            self.panAndZoom.x = pan.x;
            self.panAndZoom.y = pan.y;
            self.panAndZoom.zoom = newZoom;

            self.realignPoints();

            return newZoom;
        }

        function onUpdatedCTM(data) {
            const pan = self.panAndZoomInstance.getPan();

            self.panAndZoom.x = pan.x;
            self.panAndZoom.y = pan.y;
            self.panAndZoom.realZoom = data.a;

            self.realignPoints();
        }

        const options = {
            panEnabled: false,
            zoomEnabled: true,
            fit: true,
            center: true,
            zoomScaleSensitivity: 0.5,
            minZoom: 1,
            maxZoom: 10,
            beforePan,
            beforeZoom,
            onUpdatedCTM,
            customEventsHandler: {
                // Halt all touch events
                haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],

                // Init custom events handler
                init: function (options) {
                    const instance = options.instance;
                    let initialScale = 1;

                    // Init Hammer
                    // Listen only for pointer and touch events
                    this.hammer = Hammer(options.svgElement, {
                        inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
                    });

                    // Enable pinch
                    this.hammer.get('pinch').set({enable: true});

                    // Handle pinch
                    this.hammer.on('pinchstart pinchmove', function (ev) {
                        // On pinch start remember initial zoom
                        if (ev.type === 'pinchstart') {
                            initialScale = instance.getZoom();
                            instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y});
                        }

                        instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y});
                    });

                    // Prevent moving the page on some devices when panning over SVG
                    options.svgElement.addEventListener('touchmove', function (e) {
                        lastTouchMove = e;
                        e.preventDefault();
                    });
                },

                // Destroy custom events handler
                destroy: function () {
                    this.hammer.destroy()
                }
            }
        };

        this.panAndZoomInstance = svgPanZoom(this.svg, options);
    }

    createTimeline(initialPosition = false) {
        this.timelineDates = [];
        this.timelinePoints = [];
        this.timelineDateStart = this.patient.encounter.period ? moment(this.patient.encounter.period.start).startOf('day') : moment().startOf('day');
        this.timelineDateEnd = moment().add(1, 'day').startOf('day');

        const diff = this.timelineDateEnd.diff(this.timelineDateStart, 'days');
        const daysSize = Math.ceil(diff / 30);

        this.timelineDates.push(0);

        for (let i = 0; i < diff; i += daysSize) {
            if (i > 0) {
                this.timelineDates.push(i / diff * 100);
            }
        }

        this.timelineDates.push(100);

        const diffMinutes = this.timelineDateEnd.diff(this.timelineDateStart, 'minutes');

        Object.keys(this.data).forEach((type) => {
            this.data[type].forEach((point) => {
                const pointTimeDiff = moment(point.date).diff(this.timelineDateStart, 'minutes');
                let left = pointTimeDiff / diffMinutes * 100;

                if (left < 0) {
                    // clamp to 'in past' time
                    left = -2.2;
                }

                this.timelinePoints.push({
                    type: type,
                    left: left
                });
            });
        });

        if (initialPosition) {
            const progress = this.getDefaultTimelineProgress();

            this.setTimelineSliderDatetime(progress);
        } else {
            this.setTimelineSliderDatetime();
        }
    }

    getDefaultTimelineProgress() {
        let timelineTarget = this.timelineDateEnd;

        if (this.timelinePredefinedEndDateTime) {
            timelineTarget = moment(this.timelinePredefinedEndDateTime);
        }

        const diffTarget = timelineTarget.diff(this.timelineDateStart, 'minutes');
        const diffTotal = this.timelineDateEnd.diff(this.timelineDateStart, 'minutes');

        return diffTarget / diffTotal * 100;
    }

    onTimelineMouseDown(e) {
        e = e || window.event;

        if (e.target == this.timelineSlider) {
            return;
        }

        let pageX = e.pageX;
        let pageY = e.pageY;

        if (pageX === undefined) {
            pageX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            pageY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }

        const clickPos = {
            x: pageX,
            y: pageY
        };

        const timelineBoundingRect = this.timeline.getBoundingClientRect();

        const leftPosition = clickPos.x - timelineBoundingRect.left;

        const progress = Math.min(Math.max(leftPosition / timelineBoundingRect.width * 100, 0), 100);

        this.setTimelineSliderDatetime(progress);

        this.isTimelineSliderDragged = true;

        this.timelineSlider.classList.add('selected');

        this.timelineSliderStartPos = {
            x: pageX,
            y: pageY
        }
    }

    onTimelineTouchStart(e) {
        e = e || window.event;

        if (e.target == this.timelineSlider) {
            return;
        }

        if (e.changedTouches.length !== 1) {
            return;
        }

        const clickPos = {
            x: e.changedTouches[0].clientX,
            y: e.changedTouches[0].clientY
        };

        const timelineBoundingRect = this.timeline.getBoundingClientRect();

        const leftPosition = clickPos.x - timelineBoundingRect.left;

        const progress = Math.min(Math.max(leftPosition / timelineBoundingRect.width * 100, 0), 100);

        this.setTimelineSliderDatetime(progress);
    }

    onTimelineSliderMouseDown(e) {
        this.isTimelineSliderDragged = true;

        e = e || window.event;

        let pageX = e.pageX;
        let pageY = e.pageY;

        // IE 8
        if (pageX === undefined) {
            pageX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
            pageY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }

        this.timelineSlider.classList.add('selected');

        this.timelineSliderStartPos = {
            x: pageX,
            y: pageY
        }
    }

    onTimelineSliderMouseUp(e) {
        if (this.isTimelineSliderDragged) {
            this.timelineSlider.classList.remove('selected');
            this.isTimelineSliderDragged = false;

            this.updateBurnsDisplay();
        }

        function hasSomeParentTheClass(element, classname) {
            if (element.classList.contains(classname)) return true;
            return element.parentNode && element.parentNode.classList && hasSomeParentTheClass(element.parentNode, classname);
        }

        if (!hasSomeParentTheClass(e.target, 'multiselect-dropdown')) {
            this.showMultiselectDropdown = false;
        }
    }

    onTimelineSliderTouchStart(e) {
        if (e.changedTouches.length !== 1) {
            return;
        }

        this.isTimelineSliderDragged = true;

        this.timelineSlider.classList.add('selected');

        this.timelineSliderStartPos = {
            x: e.changedTouches[0].clientX,
            y: e.changedTouches[0].clientY
        };
    }

    onTimelineSliderTouchEnd() {
        if (this.isTimelineSliderDragged) {
            this.timelineSlider.classList.remove('selected');
            this.isTimelineSliderDragged = false;

            this.updateBurnsDisplay();
        }
    }

    onTimelineSliderMouseMove(e) {
        if (this.isTimelineSliderDragged) {
            e = e || window.event;

            let pageX = e.pageX;
            let pageY = e.pageY;

            if (pageX === undefined) {
                pageX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                pageY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }

            const movedPos = {
                x: pageX,
                y: pageY
            };

            const timelineBoundingRect = this.timeline.getBoundingClientRect();

            const leftPosition = movedPos.x - timelineBoundingRect.left;
            const progress = Math.min(Math.max(leftPosition / timelineBoundingRect.width * 100, 0), 100);

            this.setTimelineSliderDatetime(progress);
            this.updateBurnsDisplay();
        }
    }

    onTimelineSliderTouchMove(e) {
        if (this.isTimelineSliderDragged) {
            if (e.changedTouches.length !== 1) {
                return;
            }

            const movedPos = {
                x: e.changedTouches[0].clientX,
                y: e.changedTouches[0].clientY
            };

            const timelineBoundingRect = this.timeline.getBoundingClientRect();

            const leftPosition = movedPos.x - timelineBoundingRect.left;
            const progress = Math.min(Math.max(leftPosition / timelineBoundingRect.width * 100, 0), 100);

            this.setTimelineSliderDatetime(progress);
            this.updateBurnsDisplay();
        }
    }

    setTimelineSliderDatetime(progress = null) {
        if (progress) {
            const diff = this.timelineDateEnd.diff(this.timelineDateStart, 'minutes');

            this.timelineSlider.style.left = `${progress}%`;
            this.timelineSliderDateTime = moment(this.timelineDateStart).add(diff * progress / 100, 'minutes');
        }

        Object.keys(this.data).forEach((type) => {
            this.data[type].forEach((point) => {
                if (point.status === 'cancelled') {
                    return;
                }

                if (moment(point.date).isAfter(this.timelineSliderDateTime)) {
                    if (type === 'burn') {
                        point.svgPoints.nodes.forEach((node) => {
                            node.classList.add('faded');
                        });
                    } else {
                        point.svgPoints.svg.style.display = 'none';
                    }
                } else {
                    if (type === 'burn') {
                        point.svgPoints.nodes.forEach((node) => {
                            node.classList.remove('faded');
                        });
                    } else {
                        point.svgPoints.svg.style.display = 'block';
                    }
                }
            });
        });
    }

    detached() {
        if (RuntimeInfo.IsMobile) {
            this.svg.removeEventListener('touchstart', this._onTouchStart, false);
            this.svg.removeEventListener('touchend', this._onTouchEnd, false);
            this.svg.removeEventListener('touchcancel', this._onTouchCancel, false);
            this.svg.removeEventListener('touchmove', this._onTouchMove, false);
            this.svg.removeEventListener('touchmove', this._onSvgTouchMove, false);
        } else {
            this.svg.removeEventListener('mousemove', this._onMouseMove, false);
            this.svg.removeEventListener('mousedown', this._onMouseDown, false);
            this.svg.removeEventListener('mouseup', this._onMouseUp, false);
            this.svg.removeEventListener('mousemove', this._onSvgMouseMove, false);
        }

        window.removeEventListener('resize', this._onResize);

        this.timeline.removeEventListener('mousedown', this._onTimelineMouseDown, false);
        this.timeline.removeEventListener('touchstart', this._onTimelineTouchStart, false);

        this.timelineSlider.removeEventListener('mousedown', this._onTimelineSliderMouseDown, false);
        this.timelineSlider.removeEventListener('mouseup', this._onTimelineSliderMouseUp, false);
        this.timelineSlider.removeEventListener('touchstart', this._onTimelineSliderTouchStart, false);

        document.removeEventListener('mouseup', this._onTimelineSliderMouseUp, false);
        document.removeEventListener('mousemove', this._onTimelineSliderMouseMove, false);
        document.removeEventListener('touchmove', this._onTimelineSliderTouchMove, false);
        document.removeEventListener('touchend', this._onTimelineSliderTouchEnd, false);
        document.removeEventListener('touchcancel', this._onTimelineSliderTouchEnd, false);
    }

    onTouchStart(evt) {
        evt.preventDefault();

        if (evt.changedTouches.length !== 1) {
            return;
        }

        const pt = this.svg.createSVGPoint();

        pt.x = evt.changedTouches[0].clientX;
        pt.y = evt.changedTouches[0].clientY;

        const pos = pt.matrixTransform(this.svg.getScreenCTM().inverse());
        const loc = {
            x: pos.x,
            y: pos.y
        };

        this.prevMouseMovePos = loc;
        this.mouseDownPos = {
            x: loc.x / this.panAndZoom.realZoom,
            y: loc.y / this.panAndZoom.realZoom
        };
        this.isMouseDown = true;
    }

    onTouchEnd(evt) {
        evt.preventDefault();

        if (evt.changedTouches.length !== 1) {
            return;
        }

        const pt = this.svg.createSVGPoint();

        pt.x = evt.changedTouches[0].clientX;
        pt.y = evt.changedTouches[0].clientY;

        const screenPos = pt.matrixTransform(this.svg.getScreenCTM().inverse());
        const pos = {
            x: screenPos.x / this.panAndZoom.realZoom,
            y: screenPos.y / this.panAndZoom.realZoom
        };

        this.isMouseDown = false;

        if (this.mouseDownPos.x != pos.x || this.mouseDownPos.y != pos.y || this.dragPoint.isDragged) {
            return;
        }

        pos.x -= this.panAndZoom.x / this.panAndZoom.realZoom;
        pos.y -= this.panAndZoom.y / this.panAndZoom.realZoom;

        let id;

        if (evt.target && ['path', 'ellipse', 'polygon', 'rect'].indexOf(evt.target.tagName) > -1 && evt.target.parentNode && evt.target.parentNode && evt.target.parentNode.tagName === 'g') {
            id = evt.target.parentNode.getAttribute('id');
        }

        if (!id || id.split('_').length < 2) {
            return;
        }

        this.tooltipRect.style.opacity = '0';
        this.tooltipText.style.opacity = '0';

        if (this.isMultiselect) {
            const sectionParent = this.svg.getElementById(id);

            if (this.selectedMultiselect === 'burn') {
                if (evt.target.classList.contains('burn-location') || this.getBurnAmount(id) === 0) {
                    return;
                }

                if (evt.target.classList.contains('mark-location')) {
                    for (let i = 0; i < sectionParent.childNodes.length; i++) {
                        const node = (<any>sectionParent.childNodes[i]);

                        if (node.nodeType !== Node.ELEMENT_NODE) {
                            continue;
                        }

                        node.classList.remove('mark-location');
                    }

                    const itemIdx = this.multiselected.indexOf(id);

                    if (itemIdx > -1) {
                        this.multiselected.splice(itemIdx, 1);
                    }
                } else {
                    for (let i = 0; i < sectionParent.childNodes.length; i++) {
                        const node = (<any>sectionParent.childNodes[i]);

                        if (node.nodeType !== Node.ELEMENT_NODE) {
                            continue;
                        }

                        node.classList.add('mark-location');
                    }

                    this.multiselected.push(id);
                }
            } else {
                this.multiselected.push(this.addTemporaryPoint(this.selectedMultiselect, pos, id));
            }

            return;
        }

        if (evt.target.classList.contains('burn-location') && !evt.target.classList.contains('disabled') && !evt.target.classList.contains('faded')) {
            const type = evt.target.getAttribute('data-type');
            const index = evt.target.getAttribute('data-index');
            const point = this.data[type][index];

            if (point.group) {
                this.editMultiselect(type, index);
            } else {
                this.editItem(type, index);
            }
        } else {
            const disabledTypes = this.disabledTypes.slice();

            if (evt.target.classList.contains('disabled') || evt.target.classList.contains('faded') || this.getBurnAmount(id) === 0) {
                disabledTypes.push('burn');
            }

            this.dialogService.open({
                viewModel: ModalBodyMapAdd,
                model: {
                    location: id,
                    disabled: disabledTypes,
                    enabled: this.enabledTypes,
                    patient: this.patient,
                    maxDate: this.timelineDateEnd //this.timelineSliderDateTime
                }
            }).whenClosed((result) => {
                if (!result.wasCancelled) {
                    const type = result.output.type1;
                    const pointData = this.points[type];
                    const data = this.data[type];
                    const item = Object.assign({
                        index: null,
                        identifier: ++pointData.num,
                        added: true,
                        status: 'registered',
                        group: null,
                        point: {
                            x: pos.x,
                            y: pos.y
                        }
                    }, result.output);

                    if (item.location) {
                        item.locationLocalized = translations.translate(item.location);
                    }

                    data.push(item);

                    item.index = data.length - 1;

                    this.addPoint(data.length - 1, type, pointData.num, pos, item.location);

                    this.realignPoints();
                    this.createTimeline();
                    this.calculateBurns();
                }
            });
        }
    }

    onTouchCancel() {
        this.isMouseDown = false;
    }

    onTouchMove(evt) {
        const pt = this.svg.createSVGPoint();

        pt.x = evt.changedTouches[0].clientX;
        pt.y = evt.changedTouches[0].clientY;

        const pos = pt.matrixTransform(this.svg.getScreenCTM().inverse());
        const loc = {
            x: pos.x,
            y: pos.y
        };

        if (this.isMouseDown && !this.dragPoint.isDragged) {

            this.panAndZoomInstance.panBy({
                x: loc.x - this.prevMouseMovePos.x,
                y: loc.y - this.prevMouseMovePos.y
            });

            this.prevMouseMovePos = loc;
        }
    }

    onSvgTouchMove(evt) {
        const pt = this.svg.createSVGPoint();

        pt.x = evt.changedTouches[0].clientX;
        pt.y = evt.changedTouches[0].clientY;

        this.onMove(pt);
    }

    onSvgMouseMove(evt) {
        const pt = this.svg.createSVGPoint();

        pt.x = evt.clientX;
        pt.y = evt.clientY;

        this.onMove(pt);
    }

    onMouseMove(evt) {
        const pt = this.svg.createSVGPoint();

        pt.x = evt.clientX;
        pt.y = evt.clientY;

        const pos = pt.matrixTransform(this.svg.getScreenCTM().inverse());
        const loc = {
            x: pos.x,
            y: pos.y
        };

        if (this.isMouseDown && !this.dragPoint.isDragged) {
            this.panAndZoomInstance.panBy({
                x: loc.x - this.prevMouseMovePos.x,
                y: loc.y - this.prevMouseMovePos.y
            });

            this.prevMouseMovePos = loc;
        }

        if (this.isMouseDown) {
            this.tooltipRect.style.opacity = '0';
            this.tooltipText.style.opacity = '0';
        } else {
            let id;

            if (evt.target && ['path', 'ellipse', 'polygon', 'rect'].indexOf(evt.target.tagName) > -1 && evt.target.parentNode && evt.target.parentNode && evt.target.parentNode.tagName === 'g') {
                id = evt.target.parentNode.getAttribute('id');
            }

            if (!id || id.split('_').length < 2) {
                this.svg.style.cursor = 'default';

                if (evt.target) {
                    const type = evt.target.getAttribute('data-type');
                    const index = evt.target.getAttribute('data-index');

                    if (type !== null && index !== null) {
                        this.setTooltipWithData(type, index, loc);

                        this.tooltipRect.style.opacity = '0.8';
                        this.tooltipText.style.opacity = '0.8';
                    } else {
                        this.tooltipRect.style.opacity = '0';
                        this.tooltipText.style.opacity = '0';
                    }
                }

                return;
            }

            if (evt.target.classList.contains('burn-location') && !evt.target.classList.contains('disabled') && !evt.target.classList.contains('faded')) {
                this.svg.style.cursor = 'pointer';

                const type = evt.target.getAttribute('data-type');
                const index = evt.target.getAttribute('data-index');

                if (type !== null && index !== null) {
                    this.setTooltipWithData(type, index, loc);

                    this.tooltipRect.style.opacity = '0.8';
                    this.tooltipText.style.opacity = '0.8';
                } else {
                    this.tooltipRect.style.opacity = '0';
                    this.tooltipText.style.opacity = '0';
                }
            } else {
                this.tooltipRect.style.opacity = '0.8';
                this.tooltipText.style.opacity = '0.8';
                this.svg.style.cursor = 'crosshair';

                this.setTooltip([translations.translate(id)], loc.x, loc.y);
            }
        }
    }

    setTooltipWithData(type, index, loc) {
        const data = this.data[type][index];
        const tooltipData = [];

        tooltipData.push([translations.translate('type'), translations.translate(data.type1)].join(': '));

        if (data.type2) {
            const subtype = [translations.translate(data.type2)];

            if (data.type3) {
                subtype.push(translations.translate(data.type3));
            }

            tooltipData.push([translations.translate('subtype'), subtype.join(' / ')].join(': '));
        }

        tooltipData.push([translations.translate('location'), data.locationLocalized].join(': '));

        if (data.timeType == 'time-point') {
            tooltipData.push([translations.translate('date'), moment(data.date).format('DD.MM.YYYY HH:mm')].join(': '));
        } else {
            tooltipData.push([translations.translate('date'), this.i18n.tr('timeframe_' + data.timeframe)].join(': '));
        }

        this.setTooltip(tooltipData, loc.x, loc.y);
    }

    setTooltip(texts, x, y) {
        const bbox = this.tooltipText.getBBox();
        const viewport = this.svg.getBBox();
        const width = bbox.width + 10;
        const height = bbox.height + 8;

        if (width + x > viewport.width) {
            x -= width;
        }

        y -= height;

        if (y < 0) {
            y = 0;
        }

        this.tooltipText.textContent = '';

        for (let i = 0; i < texts.length; i++) {
            const txt = document.createElementNS("http://www.w3.org/2000/svg", 'tspan');

            txt.setAttributeNS(null, 'x', String(x + 5));
            txt.setAttributeNS(null, 'dx', '0');
            txt.setAttributeNS(null, 'dy', i === 0 ? '0' : '1.2em');
            txt.textContent = texts[i];

            this.tooltipText.appendChild(txt);
        }

        this.tooltipRect.setAttributeNS(null, 'width', String(width));
        this.tooltipRect.setAttributeNS(null, 'height', String(height));
        this.tooltipRect.setAttributeNS(null, 'x', String(x));
        this.tooltipRect.setAttributeNS(null, 'y', String(y));
        this.tooltipText.setAttributeNS(null, 'x', String(x + 5));
        this.tooltipText.setAttributeNS(null, 'y', String(y + 17));
    }

    onResize() {
        const ratio = 1.247689463955638;
        let width;

        if (window.innerWidth > window.innerHeight) {
            width = Math.min(window.innerWidth - 20, (window.innerHeight - 20) * ratio);
        } else {
            width = window.innerWidth - 20;
        }

        this.element.style.width = `${width}px`;
        this.svg.style.minHeight = (window.innerHeight * 0.8) + 'px';
    }

    onMouseDown(evt) {
        const pt = this.svg.createSVGPoint();

        pt.x = evt.clientX;
        pt.y = evt.clientY;

        const pos = pt.matrixTransform(this.svg.getScreenCTM().inverse());
        const loc = {
            x: pos.x,
            y: pos.y
        };

        this.prevMouseMovePos = loc;
        this.mouseDownPos = {
            x: loc.x / this.panAndZoom.realZoom,
            y: loc.y / this.panAndZoom.realZoom
        };
        this.isMouseDown = true;
    }

    async onMouseUp(evt) {
        if (!this.burnsConfig)
            this.burnsConfig = await this.loadBurns();

        const pt = this.svg.createSVGPoint();

        pt.x = evt.clientX;
        pt.y = evt.clientY;

        const screenPos = pt.matrixTransform(this.svg.getScreenCTM().inverse());
        const pos = {
            x: screenPos.x / this.panAndZoom.realZoom,
            y: screenPos.y / this.panAndZoom.realZoom
        };

        this.isMouseDown = false;

        if (this.mouseDownPos.x != pos.x || this.mouseDownPos.y != pos.y || this.dragPoint.isDragged) {
            return;
        }

        pos.x -= this.panAndZoom.x / this.panAndZoom.realZoom;
        pos.y -= this.panAndZoom.y / this.panAndZoom.realZoom;

        let id;

        if (evt.target && ['path', 'ellipse', 'polygon', 'rect'].indexOf(evt.target.tagName) > -1 && evt.target.parentNode && evt.target.parentNode && evt.target.parentNode.tagName === 'g') {
            id = evt.target.parentNode.getAttribute('id');
        }

        if (!id || id.split('_').length < 2) {
            return;
        }

        this.tooltipRect.style.opacity = '0';
        this.tooltipText.style.opacity = '0';

        if (this.isMultiselect) {
            const sectionParent = this.svg.getElementById(id);

            if (this.selectedMultiselect === 'burn') {
                if (evt.target.classList.contains('burn-location') || this.getBurnAmount(id) === 0) {
                    return;
                }

                if (evt.target.classList.contains('mark-location')) {
                    for (let i = 0; i < sectionParent.childNodes.length; i++) {
                        const node = (<any>sectionParent.childNodes[i]);

                        if (node.nodeType !== Node.ELEMENT_NODE) {
                            continue;
                        }

                        node.classList.remove('mark-location');
                    }

                    const itemIdx = this.multiselected.indexOf(id);

                    if (itemIdx > -1) {
                        this.multiselected.splice(itemIdx, 1);
                    }
                } else {
                    for (let i = 0; i < sectionParent.childNodes.length; i++) {
                        const node = (<any>sectionParent.childNodes[i]);

                        if (node.nodeType !== Node.ELEMENT_NODE) {
                            continue;
                        }

                        node.classList.add('mark-location');
                    }

                    this.multiselected.push(id);
                }
            } else {
                this.multiselected.push(this.addTemporaryPoint(this.selectedMultiselect, pos, id));
            }

            return;
        }

        if (evt.target.classList.contains('burn-location') && !evt.target.classList.contains('disabled') && !evt.target.classList.contains('faded')) {
            const type = evt.target.getAttribute('data-type');
            const index = evt.target.getAttribute('data-index');
            const point = this.data[type][index];

            if (point.group) {
                this.editMultiselect(type, index);
            } else {
                this.editItem(type, index);
            }
        } else {
            const disabledTypes = this.disabledTypes.slice();

            if (evt.target.classList.contains('disabled') || evt.target.classList.contains('faded') || this.getBurnAmount(id) === 0) {
                disabledTypes.push('burn');
            }

            this.dialogService.open({
                viewModel: ModalBodyMapAdd,
                model: {
                    location: id,
                    disabled: disabledTypes,
                    enabled: this.enabledTypes,
                    patient: this.patient,
                    maxDate: this.timelineDateEnd //this.timelineSliderDateTime
                }
            }).whenClosed((result) => {
                if (!result.wasCancelled) {
                    const type = result.output.type1;
                    const pointData = this.points[type];
                    const data = this.data[type];
                    const item = Object.assign({
                        index: null,
                        identifier: ++pointData.num,
                        added: true,
                        status: 'registered',
                        point: {
                            x: pos.x,
                            y: pos.y
                        }
                    }, result.output);

                    if (item.location) {
                        item.locationLocalized = translations.translate(item.location);
                    }

                    data.push(item);

                    item.index = data.length - 1;

                    this.addPoint(data.length - 1, type, pointData.num, pos, item.location);

                    this.realignPoints();
                    this.createTimeline();
                    this.calculateBurns();
                }
            });
        }
    }

    onMove(svgPoint) {
        const pos = svgPoint.matrixTransform(this.svg.getScreenCTM().inverse());
        const loc = {
            x: pos.x / this.panAndZoom.realZoom - this.panAndZoom.x / this.panAndZoom.realZoom,
            y: pos.y / this.panAndZoom.realZoom - this.panAndZoom.y / this.panAndZoom.realZoom
        };

        if (this.dragPoint.isDragged) {
            const pointData = this.points[this.dragPoint.type];
            const svg = this.svgPoints[this.dragPoint.type][this.dragPoint.number].svg;

            svg.setAttributeNS(null, 'x', String(loc.x - pointData.size / 2 / this.panAndZoom.zoom));
            svg.setAttributeNS(null, 'y', String(loc.y - pointData.size / 2 / this.panAndZoom.zoom));
        }
    }

    createTooltip() {
        this.tooltipRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        this.tooltipRect.setAttributeNS(null, 'style', 'fill: black; opacity: 0.8');
        this.tooltipRect.setAttributeNS(null, 'class', 'tooltipAnimation');
        this.svg.appendChild(this.tooltipRect);

        this.tooltipText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        this.tooltipText.setAttributeNS(null, 'class', 'tooltipAnimation');
        this.svg.appendChild(this.tooltipText);
    }

    addTemporaryPoint(type, position, location) {
        const data = this.points[type];
        const svgPoint = {
            location,
            position,
            data: data,
            svg: null,
            figure: null,
            text: null,
            nodes: []
        };

        if (type === 'burn') {

        } else {
            const container = this.svg.querySelector('.svg-pan-zoom_viewport');

            const svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');

            svg.setAttributeNS(null, 'x', String(position.x - data.size / 2));
            svg.setAttributeNS(null, 'y', String(position.y - data.size / 2));
            svg.setAttributeNS(null, 'width', String(data.size));
            svg.setAttributeNS(null, 'height', String(data.size));
            svg.setAttributeNS(null, 'viewBox', `0 0 ${data.size} ${data.size}`);

            container.appendChild(svg);

            const figure = document.createElementNS("http://www.w3.org/2000/svg", 'image');
            figure.setAttributeNS(null, 'width', String(data.size));
            figure.setAttributeNS(null, 'height', String(data.size));
            figure.setAttributeNS(null, 'href', `images/bodies/icons/${data.img}.svg`);
            figure.setAttributeNS("http://www.w3.org/1999/xlink", 'xlink:href', `images/bodies/icons/${data.img}.svg`);

            svg.appendChild(figure);

            const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
            text.setAttributeNS(null, 'x', String(data.size / 2));
            text.setAttributeNS(null, 'y', String(data.size / 2 + data.textOffset));
            text.setAttributeNS(null, 'text-anchor', 'middle');
            text.setAttributeNS(null, 'style', `fill: white;stroke-width: 1px; font-family: Verdana; font-size: ${this.fontSize}px;`);

            svg.appendChild(text);

            svgPoint.figure = figure;
            svgPoint.text = text;
            svgPoint.svg = svg;
        }

        return svgPoint;
    }

    addPoint(idx, type, number, position, location, group = null) {
        const data = this.points[type];
        const svgPoint = {
            type,
            position,
            location,
            data,
            number,
            svg: null,
            figure: null,
            text: null,
            nodes: []
        };
        const dataItem = this.data[type][idx];

        if (type === 'burn') {
            const sectionParent = this.svg.getElementById(location);

            for (let i = 0; i < sectionParent.childNodes.length; i++) {
                const node = (<any>sectionParent.childNodes[i]);

                if (node.nodeType !== Node.ELEMENT_NODE) {
                    continue;
                }

                node.classList.add('burn-location');
                node.setAttribute('data-type', type);
                node.setAttribute('data-index', idx);

                svgPoint.nodes.push(node);
            }
        } else {
            if (!this.svg) return;
            let container = this.svg.querySelector('.svg-pan-zoom_viewport');
            if (!container) {
                const g = document.createElement("g");
                g.className = 'fallback-container';
                this.svg.append(g);
                container = g;

                if (ConfigService.Debug)
                    console.warn("No container '.svg-pan-zoom_viewport' found!");
            }

            const svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');

            svg.setAttributeNS(null, 'x', String(position.x - data.size / 2));
            svg.setAttributeNS(null, 'y', String(position.y - data.size / 2));
            svg.setAttributeNS(null, 'width', String(data.size));
            svg.setAttributeNS(null, 'height', String(data.size));
            svg.setAttributeNS(null, 'viewBox', `0 0 ${data.size} ${data.size}`);

            container.appendChild(svg);

            const figure = document.createElementNS("http://www.w3.org/2000/svg", 'image');
            figure.setAttributeNS(null, 'data-type', type);
            figure.setAttributeNS(null, 'data-index', idx);
            figure.setAttributeNS(null, 'width', String(data.size));
            figure.setAttributeNS(null, 'height', String(data.size));
            figure.setAttributeNS(null, 'href', `images/bodies/icons/${data.img + (dataItem.healDate ? '_bordered' : '')}.svg`);
            figure.setAttributeNS("http://www.w3.org/1999/xlink", 'xlink:href', `images/bodies/icons/${data.img + (dataItem.healDate ? '_bordered' : '')}.svg`);
            figure.addEventListener('mousedown', this.pointMouseDown.bind(this, type, idx, number));
            figure.addEventListener('mouseup', this.pointMouseUp.bind(this, type, idx, number));
            figure.addEventListener('touchstart', this.pointMouseDown.bind(this, type, idx, number));
            figure.addEventListener('touchend', this.pointMouseUp.bind(this, type, idx, number));

            svg.appendChild(figure);

            const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
            text.setAttributeNS(null, 'data-type', type);
            text.setAttributeNS(null, 'data-index', idx);
            text.setAttributeNS(null, 'x', String(data.size / 2));
            text.setAttributeNS(null, 'y', String(data.size / 2 + data.textOffset));
            text.setAttributeNS(null, 'text-anchor', 'middle');
            text.setAttributeNS(null, 'style', `fill: ${dataItem.healDate ? 'black' : 'white'};stroke-width: 1px; font-family: Verdana; font-size: ${this.fontSize}px;`);
            text.addEventListener('mousedown', this.pointMouseDown.bind(this, type, idx, number));
            text.addEventListener('mouseup', this.pointMouseUp.bind(this, type, idx, number));
            text.addEventListener('touchstart', this.pointMouseDown.bind(this, type, idx, number));
            text.addEventListener('touchend', this.pointMouseUp.bind(this, type, idx, number));
            text.textContent = String(number);

            svg.appendChild(text);

            svgPoint.figure = figure;
            svgPoint.text = text;
            svgPoint.svg = svg;
        }

        this.svgPoints[type][number] = svgPoint;

        this.data[type][idx].svgPoints = svgPoint;

        if (group) {
            this.data[type][idx].group = group;

            group.items.push(this.data[type][idx]);
        }
    }

    removePoint(key, index) {
        const svgPointData = this.svgPoints[key][index];

        if (svgPointData.type === 'burn') {
            const sectionParent = this.svg.getElementById(svgPointData.location);

            for (let i = 0; i < sectionParent.childNodes.length; i++) {
                const node = (<any>sectionParent.childNodes[i]);

                if (node.nodeType !== Node.ELEMENT_NODE) {
                    continue;
                }

                node.classList.remove('burn-location');
                node.removeAttribute('data-type');
                node.removeAttribute('data-index');
            }
        } else {
            const container = this.svg.querySelector('.svg-pan-zoom_viewport');

            container.removeChild(svgPointData.svg);
        }

        delete this.svgPoints[key][index];
    }

    realignPoints() {
        Object.keys(this.svgPoints).forEach((key) => {
            Object.keys(this.svgPoints[key]).forEach((point) => {
                const pointData = this.svgPoints[key][point];

                if (!pointData.figure || !pointData.text) {
                    return;
                }

                pointData.svg.setAttributeNS(null, 'x', String(pointData.position.x - pointData.data.size / 2 / this.panAndZoom.zoom));
                pointData.svg.setAttributeNS(null, 'y', String(pointData.position.y - pointData.data.size / 2 / this.panAndZoom.zoom));
                pointData.svg.setAttributeNS(null, 'viewBox', `0 0 ${pointData.data.size * this.panAndZoom.zoom} ${pointData.data.size * this.panAndZoom.zoom}`)
            })
        });
    }

    pointMouseDown(type, idx, number, evt) {
        if (evt.cancelable) {
            evt.preventDefault();
        }

        const pt = this.svg.createSVGPoint();

        if (evt.changedTouches) {
            pt.x = evt.changedTouches[0].clientX;
            pt.y = evt.changedTouches[0].clientY;
        } else {
            pt.x = evt.clientX;
            pt.y = evt.clientY;
        }

        const pos = pt.matrixTransform(this.svg.getScreenCTM().inverse());
        const loc = {
            x: parseFloat((pos.x / this.panAndZoom.realZoom - this.panAndZoom.x / this.panAndZoom.realZoom).toFixed(2)),
            y: parseFloat((pos.y / this.panAndZoom.realZoom - this.panAndZoom.y / this.panAndZoom.realZoom).toFixed(2))
        };

        this.dragPoint.isDragged = true;
        this.dragPoint.idx = idx;
        this.dragPoint.number = number;
        this.dragPoint.type = type;
        this.dragPoint.coordinates.x = loc.x;
        this.dragPoint.coordinates.y = loc.y;
    }

    pointMouseUp(type, idx, number, evt) {
        if (evt.cancelable) {
            evt.preventDefault();
        }

        this.dragPoint.isDragged = false;

        const pt = this.svg.createSVGPoint();

        if (evt.changedTouches) {
            pt.x = evt.changedTouches[0].clientX;
            pt.y = evt.changedTouches[0].clientY;
        } else {
            pt.x = evt.clientX;
            pt.y = evt.clientY;
        }

        const pos = pt.matrixTransform(this.svg.getScreenCTM().inverse());
        const loc = {
            x: parseFloat(((pos.x - this.panAndZoom.x) / this.panAndZoom.realZoom).toFixed(2)),
            y: parseFloat(((pos.y - this.panAndZoom.y) / this.panAndZoom.realZoom).toFixed(2))
        };
        const pointLoc = this.svgPoints[type][number].position;
        let elementsAtPoint, target;

        if ('elementsFromPoint' in document) {
            elementsAtPoint = document.elementsFromPoint(pt.x, pt.y).filter((el) => ['path', 'ellipse', 'polygon', 'rect'].indexOf(el.tagName) > -1);

            target = elementsAtPoint[0];
        } else {
            elementsAtPoint = Array.prototype.slice.call((<any>document).msElementsFromPoint(pt.x, pt.y)).filter((el) => ['path', 'ellipse', 'polygon', 'rect'].indexOf(el.tagName) > -1);

            target = elementsAtPoint[1];
        }

        this.tooltipRect.style.opacity = '0';
        this.tooltipText.style.opacity = '0';

        if (elementsAtPoint.length == 0) {
            const pointData = this.points[this.dragPoint.type];
            const svg = this.svgPoints[this.dragPoint.type][this.dragPoint.number].svg;

            svg.setAttributeNS(null, 'x', String(pointLoc.x - pointData.size / 2 / this.panAndZoom.zoom));
            svg.setAttributeNS(null, 'y', String(pointLoc.y - pointData.size / 2 / this.panAndZoom.zoom));
        } else {
            const data = this.data[type][idx];

            if (this.dragPoint.coordinates.x == loc.x && this.dragPoint.coordinates.y == loc.y) {
                if (data.group) {
                    this.editMultiselect(type, idx);
                } else {
                    this.editItem(type, idx);
                }
            } else {
                if (!data.added) {
                    data.edited = true;
                }

                let id;

                if (target && target.parentNode && target.parentNode && target.parentNode.tagName === 'g') {
                    id = target.parentNode.getAttribute('id');
                }

                if (!id || id.split('_').length < 2) {
                    return;
                }

                data.location = id;
                data.locationLocalized = translations.translate(id);
                data.point.x = loc.x;
                data.point.y = loc.y;

                this.svgPoints[this.dragPoint.type][this.dragPoint.number].position = {
                    x: loc.x,
                    y: loc.y
                };
            }
        }
    }

    dateToView(value) {
        if (!value) {
            return null;
        }

        return moment(value).format('DD.MM.YYYY HH:mm');
    }

    setData(data, status = 'registered') {
        const fhirData: any = {
            identifier: [{
                value: data.identifier
            }],
            resourceType: fhirEnums.ResourceType.observation,
            status: status,
            issued: moment(data.currentDate).toJSON(),
            code: {
                text: 'body-map'
            },
            subject: {
                reference: "Patient/" + this.patient.id,
            },
            [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: {
                reference: "Encounter/" + this.patient.encounterId
            },
            effectivePeriod: {
                start: moment(data.date).toJSON()
            },
            bodySite: {
                text: `${data.point.x}_${data.point.y}_body-2.0`,
                coding: [{
                    display: data.location
                }]
            }
        };

        if (data.availableAtAdmission) {
            fhirData.extension = [{
                url: environment.nursItStructureDefinition + "available-at-admission",
                valueBoolean: data.availableAtAdmission
            }];
        }

        if (data.timeType == 'time-frame') {
            fhirData.effectivePeriod.start = null;
            fhirData.effectivePeriod.extension = [{
                url: environment.nursItStructureDefinition + "timeframe-days",
                valueInteger: parseInt(data.timeframe, 10)
            }]
        }

        if (data.healDate) {
            fhirData.effectivePeriod.end = data.healDate;
        }

        if (data.id) {
            fhirData.id = data.id;
        }

        if (data.info) {
            if (FhirService.FhirVersion > 3) {
                fhirData.note = [{
                    text: data.info
                }];
            } else {
                fhirData.comment = data.info;
            }
        }

        if (data.type1) {
            fhirData.category = [{
                text: data.type1
            }];
        }

        if (data.type2) {
            fhirData.component = [{
                code: {
                    text: data.type2
                }
            }];

            if (data.type3) {
                if (data.type1 === 'burn') {
                    if (!isNaN(parseInt(data.type3, 10))) { // convert legacy values (1,2,3 to grad_x)
                        if (data.type3 == 1) data.type3 = "grad_1";
                        else if (data.type3 == 2) data.type3 = "grad_2a";
                        else if (data.type3 == 3) data.type3 = "grad_3";
                    }

                    const codes = {
                        grad_1: LOINC.CODES.BURN_FIRST_DEGREE,
                        grad_2a: LOINC.CODES.BURN_SECOND_DEGREE,
                        grad_2b: LOINC.CODES.BURN_SECOND_DEGREE,
                        grad_3: LOINC.CODES.BURN_THIRD_DEGREE,
                        grad_4: LOINC.CODES.BURN_FOURTH_DEGREE
                    };

                    fhirData.component.push({
                        code: {
                            coding: [{
                                system: LOINC.SYSTEM,
                                code: codes[data.type3].code,
                                display: codes[data.type3].display
                            }],
                            text: data.type3
                        },
                        valueQuantity: {
                            value: this.getBurnAmount(data.location)
                        }
                    });
                } else {
                    fhirData.component.push({
                        code: {
                            text: data.type3
                        }
                    });
                }
            }
        }

        if (this.userService.practitioner) {
            fhirData.performer = [{
                reference: `Practitioner/${this.userService.practitioner.id}`,
                display: this.userService.fullNameOrUsername
            }];
        }

        return fhirData;
    }

    submit() {
        if (this.isSubmitted || this.isMultiselect) return;

        let fhirData = [];
        const deletedGroups = this.groups.filter((group) => group.markedToDelete).map((group) => {
            return this.fhirService.delete(group.resource);
        });

        if (this.dataMarkedDelete.length > 0) {
            fhirData.concat(this.dataMarkedDelete.map((item) => {
                return Fhir.Rest.GetClient().createRequest(`Observation/${item.id}`).asPut().withContent(this.setData(item, 'cancelled')).send();
            }));
        }

        Object.keys(this.data).forEach((key) => {
            fhirData = fhirData.concat(this.data[key].filter((data) => data.added && !data.deleted).map((data) => {
                return this.fhirService.create(this.setData(data)).then((result) => {
                    data.id = result.id;
                });
            }));

            fhirData = fhirData.concat(this.data[key].filter((data) => data.edited).map((data) => this.fhirService.update(this.setData(data))));
        });

        if (this.burnAreaObservation.id) {
            fhirData.push(this.fhirService.update(this.burnAreaObservation));
        } else {
            fhirData.push(this.fhirService.create(this.burnAreaObservation));
        }

        this.isSubmitted = true;

        Promise.all(deletedGroups).then(() => {
            Promise.all(fhirData).then(() => {
                const groups = this.groups.filter(group => group.added && !group.deleted).map((group) => {
                    group.items.forEach((item) => {
                        group.resource.entry.push({
                            item: {reference: `Observation/${item.id}`}
                        })
                    });

                    return this.fhirService.create(group.resource);
                });

                if (groups.length > 0) {
                    Promise.all(groups).then(() => {
                        this.controller.ok(this.data);
                    });
                } else {
                    this.controller.ok(this.data);
                }
            });
        });
    }

    cancel() {
        if (this.isMultiselect) {
            return;
        }

        this.controller.cancel();
    }

    toggleDetails() {
        if (this.isMultiselect) {
            return;
        }

        this.detailShown = !this.detailShown;
    }

    editItem(key, idx) {
        this.dialogService.open({
            viewModel: ModalBodyMapAdd, model: {
                editMode: true,
                data: this.data[key][idx],
                patient: this.patient,
                maxDate: this.timelineDateEnd //this.timelineSliderDateTime
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                if (!result.output) {
                    this.removeItem(key, idx, true);
                } else {
                    const data = Object.assign(this.data[key][idx], result.output);

                    if (!data.added) {
                        data.edited = true;
                    }

                    this.data[key].splice(idx, 1, data);

                    const figure = data.svgPoints.figure;
                    const text = data.svgPoints.text;

                    if (figure) {
                        figure.setAttributeNS(null, 'href', `images/bodies/icons/${this.points[key].img + (data.healDate ? '_bordered' : '')}.svg`);
                        figure.setAttributeNS("http://www.w3.org/1999/xlink", 'xlink:href', `images/bodies/icons/${this.points[key].img + (data.healDate ? '_bordered' : '')}.svg`);
                        text.setAttributeNS(null, 'style', `fill: ${data.healDate ? 'black' : 'white'};stroke-width: 1px; font-family: Verdana; font-size: ${this.fontSize}px;`);
                    }
                }

                this.createTimeline();
                this.calculateBurns();
            }
        });
    }

    removeItem(key, idx, force = false) {
        function del() {
            const data = this.data[key][idx];

            if (!data.added) {
                data.status = 'cancelled';
                this.dataMarkedDelete.push(data);
            } else {
                data.deleted = true;
            }

            this.removePoint(key, data.identifier);

            this.points[data.type1].num = 0;

            this.data[key].filter((item) => item.type1 == data.type1).forEach((item) => {
                this.points[data.type1].num = Math.max(this.points[data.type1].num, item.identifier);
            });
        }

        if (force) {
            del.apply(this);
        } else {
            this.dialogService.open({
                viewModel: Prompt, model: {message: translations.translate("confirm")}
            }).whenClosed((result) => {
                if (!result.wasCancelled) {
                    del.apply(this);
                }
            });
        }
    }

    toggleMultiselectChoice() {
        if (this.detailShown) {
            return;
        }

        this.showMultiselectDropdown = !this.showMultiselectDropdown;
    }

    toggleMultiselect(mode = undefined) {
        this.isMultiselect = typeof mode !== 'undefined' ? mode : !this.isMultiselect;

        if (!this.isMultiselect) {
            if (this.selectedMultiselect === 'burn') {
                const marked = this.svg.querySelectorAll('.mark-location');

                for (let i = 0; i < marked.length; i++) {
                    marked[i].classList.remove('mark-location')
                }

                this.multiselected = [];
            } else {
                const container = this.svg.querySelector('.svg-pan-zoom_viewport');

                this.multiselected.forEach((multiselected) => {
                    container.removeChild(multiselected.svg);
                });

                this.multiselected = [];
            }

            this.selectedMultiselect = null;
        }
    }

    selectMultiselect(type) {
        this.isMultiselect = true;
        this.showMultiselectDropdown = false;
        this.selectedMultiselect = type;
    }

    editMultiselect(type, index) {
        const pointData = this.points[type];
        const point = this.data[type][index];
        const group = point.group;
        const locations = group.items.map((item) => item.location);

        this.dialogService.open({
            viewModel: ModalBodyMapAdd, model: {
                location: locations, editMode: true,
                data: point,
                patient: this.patient,
                maxDate: this.timelineDateEnd //this.timelineSliderDateTime
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                if (result.output) {
                    group.items.forEach((item) => {
                        item.type2 = result.output.type2;
                        item.type3 = result.output.type3;
                        item.currentDate = result.output.currentDate;
                        item.date = result.output.date;
                        item.info = result.output.info;
                        item.healDate = result.output.healDate;
                        item.timeType = result.output.timeType;
                        item.timeframe = result.output.timeframe;
                        item.availableAtAdmission = result.output.availableAtAdmission;


                        if (!item.added) {
                            item.edited = true;
                        }

                        const figure = item.svgPoints.figure;
                        const text = item.svgPoints.text;

                        if (figure) {
                            figure.setAttributeNS(null, 'href', `images/bodies/icons/${pointData.img + (item.healDate ? '_bordered' : '')}.svg`);
                            figure.setAttributeNS("http://www.w3.org/1999/xlink", 'xlink:href', `images/bodies/icons/${pointData.img + (item.healDate ? '_bordered' : '')}.svg`);
                            text.setAttributeNS(null, 'style', `fill: ${item.healDate ? 'black' : 'white'};stroke-width: 1px; font-family: Verdana; font-size: ${this.fontSize}px;`);
                        }
                    })
                } else {
                    group.items.forEach((item) => {
                        this.removeItem(item.type1, item.index, true);
                    });

                    if (group.added) {
                        group.deleted = true;
                    } else {
                        group.markedToDelete = true;
                    }
                }

                this.createTimeline();
                this.calculateBurns();
            }
        });
    }

    openMultiselect() {
        if (this.multiselected.length < 1) {
            return;
        }

        this.dialogService.open({
            viewModel: ModalBodyMapAdd,
            model: {
                location: this.selectedMultiselect === 'burn' ? this.multiselected : this.multiselected.map((m) => m.location),
                patient: this.patient,
                maxDate: this.timelineDateEnd,
                enabled: [this.selectedMultiselect]
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                const type = this.selectedMultiselect;
                const group = this.createGroup(type);

                this.multiselected.forEach((multiselected) => {
                    const pointData = this.points[type];
                    const data = this.data[type];
                    const location = type === 'burn' ? multiselected : multiselected.location;
                    const position = type === 'burn' ? {x: 0, y: 0} : multiselected.position;
                    const item = {
                        index: null,
                        type1: type,
                        type2: result.output.type2,
                        type3: result.output.type3,
                        currentDate: result.output.currentDate,
                        healDate: result.output.healDate,
                        date: result.output.date,
                        info: result.output.info,
                        identifier: ++pointData.num,
                        timeType: result.output.timeType,
                        timeframe: result.output.timeframe,
                        availableAtAdmission: result.output.availableAtAdmission,
                        added: true,
                        status: 'registered',
                        location: location,
                        locationLocalized: '',
                        point: position
                    };

                    if (item.location) {
                        item.locationLocalized = translations.translate(item.location);
                    }

                    data.push(item);

                    item.index = data.length - 1;

                    this.addPoint(data.length - 1, type, pointData.num, position, location, group);

                    this.realignPoints();
                });

                this.toggleMultiselect();
                this.createTimeline();
                this.calculateBurns();
            }
        });
    }

    createGroup(type, resource = null, added = true) {
        const group = {
            resource: resource || {
                resourceType: fhirEnums.ResourceType.list,
                status: 'current',
                mode: 'working',
                code: {
                    coding: [
                        {
                            system: RuntimeInfo.SystemHeader + "/body-map-group",
                            code: type
                        },
                    ]
                },
                subject: {reference: `${fhirEnums.ResourceType.patient}/${this.patient.id}`},
                encounter: {reference: `${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`},
                date: moment(Date.now()).toJSON(),
                entry: []
            },
            items: [],
            deleted: false,
            markedToDelete: false,
            added,
            type
        };

        this.groups.push(group);

        return group;
    }

    reset() {
        this.panAndZoomInstance.reset();

        const progress = this.getDefaultTimelineProgress();

        this.setTimelineSliderDatetime(progress);
    }

    loadBurns() {
        return new Promise((resolve, reject) => {
            new HttpClient().get("./config/body-burn.json").then((msg: HttpResponseMessage) => {
                try {
                    let json = JSON.parse(msg.response);
                    resolve(json);
                } catch (e) {
                    console.warn(e);
                    reject("Error when reading Mappings");
                }
            })
        })
    }

    getBurnAmount(location) {
        const burn = this.burnsConfig[location];

        if (typeof burn === 'string') {
            return this.burnsConfig[burn];
        } else {
            return burn;
        }
    }

    calculateBurns() {
        this.updateBurnsDisplay();
        this.updateObservation();
    }

    getBurns(timelineLimit = false) {
        const burns = {
            1: 0,
            2: 0,
            3: 0
        };
        const areas = {};

        this.data.burn.forEach((burn) => {
            if (burn.hasOwnProperty('deleted') || burn.status === 'cancelled' || timelineLimit && moment(burn.date).isAfter(this.timelineSliderDateTime)) {
                return;
            }

            let degree = burn.type3;
            let burnArea = burn.location;
            let burnValue = this.burnsConfig[burnArea];

            if (typeof burnValue === 'string') {
                burnArea = burnValue;
                burnValue = this.burnsConfig[burnArea];
            }

            if (isNaN(parseInt(degree, 10))) {
                if (degree == "grad_1") degree = 1;
                else if (degree == "grad_2a" || degree == "grad_2b") degree = 2;
                else if (degree == "grad_3") degree = 3;
            }

            if (!areas.hasOwnProperty(burnArea)) {
                burns[degree] += burnValue;
            }

            areas[burnArea] = true;
        });

        return burns;
    }

    getBurnLevel(burns) {
        let burnLevel = 0;

        if (burns[2] >= 50 || burns[3] >= 20) {
            burnLevel = 4;
        } else if (burns[2] >= 25 && burns[2] < 50 || burns[3] >= 10 && burns[3] < 20) {
            burnLevel = 3;
        } else if (burns[2] >= 15 && burns[2] < 25 || burns[3] > 0 && burns[3] < 10) {
            burnLevel = 2;
        } else if (burns[1] > 0 || burns[2] > 0 && burns[2] < 15 || burns[3] > 0 && burns[3] < 2) {
            burnLevel = 1;
        }

        return burnLevel;
    }

    updateBurnsDisplay() {
        const burns = this.getBurns(true);
        const burnLevel = this.getBurnLevel(burns);
        const burnLevels = [
            translations.translate('burns_none'),
            translations.translate('burns_minor'),
            translations.translate('burns_intermediate'),
            translations.translate('burns_severe'),
            translations.translate('burns_most_severe')
        ];

        this.burnDisplayLevel = burnLevel;

        this.burnDisplayLevelText = burnLevels[burnLevel];

        this.burnDisplayFirstDegree = burns[1].toFixed(2);

        this.burnDisplaySecondDegree = burns[2].toFixed(2);

        this.burnDisplayThirdDegree = burns[3].toFixed(2);
    }

    updateObservation() {
        const burns = this.getBurns();
        const burnLevel = this.getBurnLevel(burns);
        const codes = {
            1: LOINC.CODES.BURN_FIRST_DEGREE,
            2: LOINC.CODES.BURN_SECOND_DEGREE,
            3: LOINC.CODES.BURN_THIRD_DEGREE,
            4: LOINC.CODES.BURN_FOURTH_DEGREE
        };

        this.burnAreaObservation.effectiveDateTime = moment().toJSON();
        this.burnAreaObservation.valueCodeableConcept = {
            coding: [{
                system: LOINC.SYSTEM,
                code: burnLevel > 0 ? codes[burnLevel].code : '',
                display: burnLevel > 0 ? codes[burnLevel].display : ''
            }],
            text: burnLevel
        };

        this.burnAreaObservation.component = [{
            code: {
                coding: [{
                    system: LOINC.SYSTEM,
                    code: LOINC.CODES.BURN_FIRST_DEGREE.code,
                    display: LOINC.CODES.BURN_FIRST_DEGREE.display
                }],
                text: '1'
            },
            valueQuantity: {
                value: burns[1].toFixed(2)
            }
        }, {
            code: {
                coding: [{
                    system: LOINC.SYSTEM,
                    code: LOINC.CODES.BURN_SECOND_DEGREE.code,
                    display: LOINC.CODES.BURN_SECOND_DEGREE.display
                }],
                text: '2'
            },
            valueQuantity: {
                value: burns[2].toFixed(2)
            }
        }, {
            code: {
                coding: [{
                    system: LOINC.SYSTEM,
                    code: LOINC.CODES.BURN_THIRD_DEGREE.code,
                    display: LOINC.CODES.BURN_THIRD_DEGREE.display
                }],
                text: '3'
            },
            valueQuantity: {
                value: burns[3].toFixed(2)
            }
        }];
    }

    shownPointsChanged(idx) {
        let val = true, i = 0

        this.shownPoints.forEach((v) => {
            if (!v) {
                i++
                val = false
            }
        })

        this.shownPointsAllElement.indeterminate = i !== 0 && i !== this.shownPoints.length
        this.shownPointsAll = val

        this.hidePointsChanged()
    }

    shownPointsAllChanged() {
        this.shownPoints = this.shownPoints.map((val) => this.shownPointsAll)

        this.hidePointsChanged()
    }

    hidePointsChanged() {
        Object.keys(this.svgPoints).forEach((key, idx) => {
            Object.keys(this.svgPoints[key]).forEach((point) => {
                const svgPointData = this.svgPoints[key][point];

                if (svgPointData.type === 'burn') {
                    const sectionParent = this.svg.getElementById(svgPointData.location);

                    for (let i = 0; i < sectionParent.childNodes.length; i++) {
                        const node = (<any>sectionParent.childNodes[i]);

                        if (node.nodeType !== Node.ELEMENT_NODE) {
                            continue;
                        }

                        if (this.shownPoints[idx]) {
                            node.classList.remove('disabled');
                        } else {
                            node.classList.add('disabled');
                        }

                    }
                } else {
                    if (this.shownPoints[idx]) {
                        this.svgPoints[key][point].figure.classList.remove('point-disabled');
                        this.svgPoints[key][point].text.classList.remove('point-disabled');
                    } else {
                        this.svgPoints[key][point].figure.classList.add('point-disabled');
                        this.svgPoints[key][point].text.classList.add('point-disabled');
                    }
                }
            })
        })
    }
}

