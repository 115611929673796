import {inject, observable} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {translations} from "../classes/translations";
import {LOINC} from '../classes/Codes';
import {ModalTodoListProcedureEdit} from "./modal-todo-list-procedure-edit";
import {NitTools} from "../classes/NursitTools";
import {UserService} from "../services/UserService";
import {FhirService} from "../services/FhirService";
import {fhirEnums} from "../classes/fhir-enums";
import * as moment from 'moment';

@inject(Element, DialogController, DialogService, UserService, FhirService)
export class ModalShiftReport {
    element;
    dialogService;
    controller;
    userService: UserService;
    fhirService: FhirService;
    mode: string;
    patient;
    report;
    procedure;
    comment;

    dpOptions = {
        useCurrent: true,
        format: 'DD.MM.YYYY HH:mm',
        locale: translations.language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        keepInvalid: true,
        focusOnShow: false
    };

    @observable dateElement;

    constructor(element, dialogController, dialogService, userService, fhirService: FhirService) {
        this.element = element;
        this.dialogService = dialogService;
        this.controller = dialogController;
        this.userService = userService;
        this.fhirService = fhirService;
    }

    activate(params) {
        this.patient = params.patient;
        this.mode = params.mode;

        if (params.mode === 'add') {
            this.report = {
                status: 'registered',
                datetime: new Date(),
                shift: 'day',
                markSupplement: false,
                nursingTransfer: false,
                additionalInfo: false,
                reportText: '',
                comment: ''
            }
        } else {
            if (!params.report) {
                throw new Error('No report provided');
            }

            this.report = NitTools.Clone(params.report);
            this.report.datetime = new Date(this.report.datetime);

            this.fhirService.fetch(`Procedure?part-of=${this.report.id}`).then((result: any) => {
                if (result.length > 0) {
                    this.procedure = result[0];
                }
            });
        }
    }

    attached() {
        this.element.style.maxWidth = "660px";
        this.element.style.width = "60%";
    }

    dateElementChanged() {
        this.dateElement.methods.maxDate(moment().endOf('day').toDate());
    }

    submit() {
        const report: any = {
            resourceType: fhirEnums.ResourceType.observation,
            subject: {reference: `${fhirEnums.ResourceType.patient}/${this.patient.id}`},
            [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: {reference: `${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`},
            status: this.report.status,
            code: {
                coding: [{
                    system: LOINC.SYSTEM,
                    code: LOINC.CODES.REPORT.code
                }]
            },
            effectiveDateTime: this.report.datetime,
            valueString: this.report.reportText,
            component: [{
                code: {
                  text: 'type'
                },
                valueString: 'nursing'
              }, 
              {
                code: {
                    text: 'shift'
                },
                valueString: this.report.shift
            }, {
                code: {
                    text: 'mark-supplement'
                },
                valueString: this.report.markSupplement.toString()
            }, {
                code: {
                    text: 'nursing-transfer'
                },
                valueString: this.report.nursingTransfer.toString()
            },
                {
                    code: {
                        text: 'additional-info'
                    },
                    valueString: this.report.additionalInfo.toString()
                }],
            [FhirService.FhirVersion > 3 ? 'note' : 'comment']: FhirService.FhirVersion > 3 ? [{ text: this.report.comment }] : this.report.comment,
        };

        if (this.userService.practitioner) {
            report.performer = [{
                reference: `Practitioner/${this.userService.practitioner.id}`,
                display: this.userService.fullNameOrUsername
            }];
        }

        if (this.mode === 'add') {
            this.fhirService.create(report).then((result) => {
                this.controller.ok(result);
            });
        } else {
            report.id = this.report.id;

            this.fhirService.update(report).then((result) => {
                this.controller.ok(result);
            });
        }
    }

    close() {
        this.controller.close();
    }

    openProcedure() {
        this.dialogService.open({
            viewModel: ModalTodoListProcedureEdit,
            model: {procedure: this.procedure, patient: this.patient}
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
            }
        });
    }
}

