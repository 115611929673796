import {DialogController, DialogService} from "aurelia-dialog";
import {FhirService} from "../services/FhirService";
import {autoinject} from "aurelia-framework";
import {IFormSetting} from "../classes/IFormSettings";
import {ConfigService} from "../services/ConfigService";
import {UserService} from "../services/UserService";
import {QuestionnaireService} from "../services/QuestionnaireService";
import {fhirEnums} from "../classes/fhir-enums";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {PatientItem} from "../classes/Patient/PatientItem";
import {SubdelegationInfo} from "../../views/patient/subdelegation";
import * as Fhir from "../classes/FhirModules/Fhir";
import ResourceType = fhirEnums.ResourceType;
import {NitTools} from "../classes/NursitTools";
import {HumanName} from "../classes/HumanName";
import {LocationService} from "../services/LocationService";
import {set} from "lodash";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {Router} from "aurelia-router";
const moment = require("moment");

@autoinject
export class ModalSubdelegationList {
    cfg: IFormSetting;
    questionnaire: any;
    subDelegations = [];
    onlyForCurrentUser : boolean = true;

    constructor(protected router: Router, protected controller: DialogController, protected fhirService: FhirService) { }


    async activate(settings) {
        this.onlyForCurrentUser = settings.onlyCurrentUser;

        RuntimeInfo.IsLoading = true;
        await this.loadData();

        RuntimeInfo.IsLoading = false;
    }

    gotoEncounter(encounterId) {
        if (!encounterId) return;
        this.router.navigateToRoute('encounter', {id: encounterId});
        this.controller.ok();
    }

    displayList;

    async loadData() {
        this.cfg = ConfigService.GetFormSettings('subdelegation');
        if (this.cfg && this.cfg.questionnaireName) {
            this.questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(this.cfg.questionnaireName);
            if (!this.questionnaire) return;

            const responses = await this.fhirService.fetch(`QuestionnaireResponse?questionnaire=${QuestionnaireService.GetQuestionnaireQueryUrl(this.questionnaire)}&status=completed,amended&_count=250`);

            for (const response of responses) {
                const sdInfo = new SubdelegationInfo(response, this.questionnaire);
                if (this.onlyForCurrentUser && sdInfo.forPractitionerId != UserService.Practitioner?.id) continue;

                const encounterId = Fhir.Tools.StripId(response.context);

                if (encounterId) {
                    let encounterAndPatient = await this.fhirService.fetch(`Encounter?_id=${encounterId}&_include=Encounter:subject`);
                    let encounter = encounterAndPatient.find(o=>o.resourceType === ResourceType.encounter);
                    let patient = encounterAndPatient.find(o=>o.resourceType === ResourceType.patient);

                    sdInfo["encounter"] = encounter;
                    sdInfo["patient"] = patient;
                    if (patient?.name && patient.name[0]) {
                        sdInfo["patientName"] = patient?.name[0] ? HumanName.GetText(patient.name[0]) : 'Ohne Name/Patient(!)';

                        for (const loc of encounter.location) {
                            const location: any = LocationService.LocationById(Fhir.Tools.StripId(loc.location))
                            if (location?.name && location.physicalType?.coding?.find(o => o.code === "wa")) {
                                sdInfo["location"] = location.name;
                                break;
                            }
                        }
                    }
                }

                sdInfo["sort0"] = sdInfo.expiration ? moment(sdInfo.expiration).format("YYYY-MM-DDTHH:mm:ss") : '';
                sdInfo["sort1"] = moment(response.authored).format("YYYY-MM-DDTHH:mm:ss");
                sdInfo["sort2"] = sdInfo["location"]||'-';
                sdInfo["sort3"] = sdInfo["patientName"]||'?';
                sdInfo["sort4"] = sdInfo.affectedGroups;
                sdInfo["sort5"] = sdInfo.fromUserDisplay;
                sdInfo["sort6"] = sdInfo.forUserDisplay;

                if (sdInfo["encounter"] && sdInfo["patient"] && sdInfo["patientName"])
                    this.subDelegations.push(sdInfo);
            }

            this.sort(0);
            console.log(this.subDelegations);
        }
    }

    currentSortField = -1;
    sortAsc = true;
    sort(index : number) {
        if (this.currentSortField != index) {
            this.sortAsc = true;
        } else {
            this.sortAsc = !this.sortAsc;
        }

        const eles = document.querySelectorAll("span.sort-current");
        for (let i = 0; i < eles.length; i++) {
            eles[i].remove();
        }
        $(".sort-tab").removeClass("sort-tab");

        const ele = document.querySelector(`span.sort${index}`);
        if (ele?.parentElement) {
            const span = document.createElement('span');
            span.classList.add("sort-current", (this.sortAsc ? "sort-current-asc" : "sort-current-desc"));
            ele.parentElement.append(span);
            ele.parentElement.classList.add("sort-tab");
        }

        this.displayList = this.sortByFieldIndex(index);
        this.currentSortField = index;
    }

    sortByFieldIndex(index : number) {
        return this.subDelegations.sort((a,b) => {
            const v1 = String(a[`sort${index}`]);
            const v2 = String(b[`sort${index}`]);

            return this.sortAsc ? v1.localeCompare(v2) : v2.localeCompare(v1);
        })
    }

    submit() {
        this.controller.ok();
    }

    cancel() {
        this.controller.cancel();
    }
}
