import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {FhirService} from "../services/FhirService";

@autoinject
export class ModalLooseImageEditDialog {
    _media: any;
    text: string;
    print: boolean = false;
    showPrint : boolean = true;

    get media() {
        return this._media;
    }

    set media(value) {
        this._media = value;
        if (this._media) {
            this.text = this.media.content?.title;
            
            if (this._media.identifier) {
                const ident = this._media.identifier.find(o => o.system.endsWith(("/PrintImage")));
                if (ident) {
                    this.print = ident.value == "true";
                }
            }
        }
    }


    ok() {
        this.controller.ok({delete: false, text: this.text, print: this.print, media: this.media});
    }

    delete() {
        this.controller.ok({delete: true, media: this.media});
    }

    constructor(protected controller: DialogController) {
    }

    async activate(data) {
        this.media = data;
        const subtypeName = FhirService.FhirVersion > 3 ? 'modality' : 'subtype';
        if (this.media[subtypeName]?.coding) {
            // no print checkbox for order-images
            this.showPrint = typeof this.media[subtypeName].coding.find(o=>o.code === 'order-image') === "undefined";
        }
    }
}
