import {autoinject, bindable} from "aurelia-framework";
import {PatientItem} from "../classes/Patient/PatientItem";
import {DialogController, DialogService} from "aurelia-dialog";

@autoinject
export class ModalRelationSelect {
    model : IModalRelationSelectModel;
    selectedPatientId : string;

    constructor(protected controller : DialogController) {
    }

    activate(params) {
        this.model = params;
        if (params.selectedId)
            this.selectedPatientId = params.selectedId;
    }

    async submit() {
        this.controller.ok(this.selectedPatientId);
    }

    async cancel()
    {
        this.controller.cancel();
    }
}

export interface IModalRelationSelectModel {
    title: string;
    patientName : string;
    encounters: PatientItem[];
    relation : string;
    selectedId? : string;
}
