import {autoinject, bindable, inject} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {I18N} from "aurelia-i18n";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {ConfigService} from "../services/ConfigService";
import * as moment from "moment";
import {FhirService} from "../services/FhirService";
import {Tools} from "../classes/FhirModules/Tools";
import {Router} from "aurelia-router";
import {NitTools} from "../classes/NursitTools";

@autoinject
export class modalHistorySearch {
    @bindable message: string;
    @bindable title: string;
    @bindable noText: string;
    @bindable yesText: string;
    @bindable showNo: boolean;
    isBusy: boolean = false;
    controller: DialogController;
    service: DialogService;
    yesButtonClass: string = 'btn-default';
    noButtonClass: string = 'btn-default';
    headerClass: string = '';
    headerCorrospondingClass: string = '';
    showCancel: boolean = true;
    i18n: I18N;
    stateEnterSearchParams: boolean = true;
    caseId: string;
    born: Date; // = new Date(1990,2,27);
    patientId: string;
    firstName: string;
    lastName: string;
    items: { display: string, encounters: any[], patient: any }[] = [];
    listHolder: HTMLDivElement;
    searchUrl: string;
    static _fhirService : FhirService;
    get fhirService() : FhirService {
        if (!modalHistorySearch._fhirService)
            modalHistorySearch._fhirService = new FhirService();

        return modalHistorySearch._fhirService;
    }

    dpOptions = {
        format: RuntimeInfo.DateFormat,
        defaultDate: false,
        icons: {close: 'glyphicon glyphicon-ok'}
    }


    constructor(controller : DialogController, service : DialogService, i18n: I18N, protected router: Router) {
        this.controller = controller;
        this.service = service;
        this.i18n = i18n;
        this.controller.settings.centerHorizontalOnly = true;
    }

    attached() {
        if (RuntimeInfo.IsIE) {
            try {
                let testDiv = document.createElement("div");
                testDiv.innerHTML = this.message;
                testDiv.style.opacity = "0";
                testDiv.style.position = "absolute";
                testDiv.style.top = "0px";
                testDiv.style.left = "0px";
                testDiv.style.border = "1px sold green";
                document.body.appendChild(testDiv);

                let w = testDiv.clientWidth;
                document.body.removeChild(testDiv);

                let dlg: HTMLDivElement = document.querySelector(".ux-dialog");
                if (dlg) {
                    dlg.style.width = (w + 32 + 16) + "px";
                }
            } catch (e) {
                if (ConfigService.Debug) {
                    console.warn("in Prompt.attached:", e);
                }
            }
        }

        if (this.patientId) this.startSearch(true);
    }

    activate(params) {
        if (!params) return;
        this.lastName = params.lastName;
        this.firstName = params.firstName;
        this.born = params.born;
        this.patientId = params.patientId;
    }

    async gotoEncounter(encounter) {
        RuntimeInfo.TogglePatientListLG(true);
        this.router.navigateToRoute('encounter', {id: encounter.id});
        await this.controller.cancel();
    }

    async startSearch(searchById: boolean = false) {
        this.isBusy = true;
        this.stateEnterSearchParams = false;
        this.items = [];
        this.listHolder.style.maxHeight = `${window.innerHeight - 200}px`;

        const params = ['_count=250'];
        if (searchById && this.patientId) {
            this.searchUrl = `Encounter`;
            params.push('_include=Encounter:patient');
            params.push(`patient=Patient/${this.patientId}`);
        } else {
            if (this.firstName || this.lastName || this.born) {
                // start search with patient, join encounter
                this.searchUrl = 'Patient';
                params.push('_revinclude=Encounter:patient');
                if (this.firstName) {
                    params.push("name:equals=" + this.firstName)
                }

                if (this.lastName) {
                    params.push("name:equals=" + this.lastName)
                }

                if (this.born) {
                    const d = moment(this.born, RuntimeInfo.DateFormat);
                    params.push(`birthdate=${d.format("YYYY-MM-DD")}`);
                }
            } else if (this.caseId) {
                // start search with encounters, join patients
                this.searchUrl = 'Encounter';
                params.push('_include=Encounter:patient');
                params.push(`identifier=${this.caseId}`);
            } else {
                this.stateEnterSearchParams = true;
                return;
            }
        }

        const searchResult = await this.fhirService.fetch(`${this.searchUrl}?${params.join('&')}`);
        this.parseSearchResult(searchResult);

        this.isBusy = false;
        /*for (let i = 0; i < 100; i++)
            this.items.push({display: "Item " + i, patient: undefined, encounters: []}); */
    }

    parseSearchResult(searchResult: any[]) {
        const patients = <any[]>(searchResult.filter(o => o.resourceType == 'Patient'));
        const encounters = <any[]>(searchResult.filter(o => o.resourceType == 'Encounter'));
        this.items = [];

        for (const p of patients) {
            const pat = Tools.GeneratePatientTextObject(p);

            let encs : any[] = encounters.filter(o => o.subject && o.subject.reference && o.subject.reference.endsWith('/' + p.id));
            for (const enc of encs) {
                let start = '';
                let end = '';
                let divider = '';
                let visitNr = '';
                enc.status = this.i18n.tr(enc.status);

                if (enc.period) {
                    if (enc.period.start) start = moment(enc.period.start).format(RuntimeInfo.DateTimeFormat);
                    if (enc.period.end) end = moment(enc.period.end).format(RuntimeInfo.DateTimeFormat);

                    if (start && end) divider = ' - ';
                }

                if (NitTools.IsArray(enc.identifier)) {
                    let vn = enc.identifier.find(o => o.system.toUpperCase().indexOf('VISITNUMBER') > -1);
                    if (!vn) {
                        vn = enc.identifier.find(o=>o.type?.coding?.find(o=>o.code?.toUpperCase() === 'VN'));
                    }

                    if (vn?.value) visitNr = vn.value;
                }

                if (!visitNr || visitNr === 'undefined')
                    visitNr = this.i18n.tr('n_a');

                enc.text = {
                    div: visitNr,
                    status: 'generated'
                }
            }

            if (this.caseId) {
                // case id is given so filter the encounters for the identifier
                encs = encs.filter(o =>
                    (o.id.indexOf(this.caseId) > -1) || (o.identifier && o.identifier.find(i => i.value.indexOf(this.caseId) > -1))
                );
                // only add the patient if it has at least one matching encounter
                if (encs.length > 0) {
                    this.items.push({
                        display: pat.display,
                        encounters: encs,
                        patient: p
                    });
                }
            } else {
                // no case id given so just add the patient
                this.items.push({
                    display:  pat.display,
                    encounters: encs,
                    patient: p
                });
            }
        }

        if (this.items.length === 0) {
            this.items.push({display: '<i>Kein Fall mit diesen Suchparametern</i>', patient: undefined, encounters: undefined});
        }
    }
}
