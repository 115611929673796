import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";

@autoinject
export class ModalCaveAdd {
    caption: string;
    title : string;
    text : string;
    targetDate :Date;

    constructor(protected controller:DialogController) {
    }

    activate(params) {
        Object.assign(this, params);
    }

    async submit() {
        await this.controller.ok(this);
    }
}